import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import { Accordion, Form } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useStore from "../../../hooks/useStore";
import { convertToNestedFormatEnv, findKpi } from "../../../utils/helpers";
import {
  ApprovalEnums,
  GOVERNANCE_STATIC_GROUPS,
  // NON_EDITABLE_FIELDS,
  SMALL_GROUPS,
  USER_ROLE,
} from "../../../constants/constants";
import { toJS } from "mobx";
import InfoIcon from "../../../components/infoIcon";
import numToRoman from "../../../helpers/numToRoman";
import { governanceCalculations } from "../../../helpers/groupDataCalculations";
import { useAuth } from "../../../context/authProvider";

const GovernanceApproval = () => {
  const {
    state: { year, groupConstant, dropDownValues },
  } = useLocation();

  const {
    governance: { fetch, formData },
    formStore: { approveForm, updateFields },
    companyStore: { kpiName },
    userStore: { employeeKpis, user },
  } = useStore();
  const [data, setData] = useState([]);
  const [expanded, setExpanded] = useState(0);
  const { showAlert } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    fetch({ year });
  }, [fetch, year]);

  useEffect(() => {
    setData(
      formData?.map((ele) => ({
        ...ele,
        fieldName: toJS(ele.fieldName)?.name,
        guidance: toJS(ele.fieldName)?.guidance,
      }))
    );
  }, [formData]);

  const renderData = convertToNestedFormatEnv({
    data,
    label: GOVERNANCE_STATIC_GROUPS[groupConstant],
  });
  const isNoComment = ["GovernanceKpis", "GovernanceParameters"].includes(
    groupConstant
  );
  const tableHeader = useMemo(
    () =>
      isNoComment
        ? [
            "Sr. No.",
            "Title",
            groupConstant === "GovernanceKpis" ? "Value" : "Description",
            "Attachment",
          ]
        : ["Sr. No.", "Title", "Value", "Comment", "Attachment"],
    [groupConstant, isNoComment]
  );

  const formId = data?.[0]?.formId;
  const kpi = useMemo(
    () => findKpi({ kpis: employeeKpis, value: kpiName, field: "name" }),
    [employeeKpis, kpiName]
  );
  return (
    <div className="add-water-page">
      <div className="card p-4">
        <Accordion
          className="water-table-accordion"
          defaultActiveKey={0}
          activeKey={expanded}
          onSelect={(eventKey) => {
            setExpanded(eventKey);
          }}
        >
          {renderData?.map((accordion, index) => (
            <Accordion.Item
              key={`${accordion.group}-${index}-accordion-item`}
              eventKey={index}
            >
              <Accordion.Header>
                <tr style={{ display: "flex", gap: "4px" }}>
                  <td>{index + 1}</td>
                  <td colSpan={7}>{accordion.label}</td>
                </tr>
              </Accordion.Header>
              <Accordion.Body>
                <Form
                  onSubmit={(event) => {
                    event.preventDefault();
                    const payload = data?.filter((e) =>
                      e.group.startsWith(accordion.group)
                    );
                    updateFields({
                      data: payload,
                      type: "governance",
                      formId,
                      showAlert,
                      year,
                    });
                    return;
                  }}
                >
                  <table className="table align-middle table-bordered table-nowrap mb-0">
                    <thead>
                      <tr>
                        {tableHeader.map((head) => (
                          <th key={head}>{head}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {accordion?.value?.map((item, ind) =>
                        item?.label ||
                        (item?.group === "C1" &&
                          groupConstant === "EconomicPerformance") ? (
                          <>
                            {item?.label && (
                              <HeadRow
                                colSpan={tableHeader?.length - 1}
                                label={item?.label}
                                cd
                                sr={SMALL_GROUPS[ind]}
                              />
                            )}
                            {item?.value?.map((ele, i) => (
                              <Row
                                data={ele}
                                sr={numToRoman(i + 1)}
                                dropDownValues={dropDownValues}
                                setData={setData}
                                isNoComment={isNoComment}
                                groupConstant={groupConstant}
                              />
                            ))}
                          </>
                        ) : (
                          <Row
                            data={item}
                            sr={SMALL_GROUPS[ind]}
                            dropDownValues={dropDownValues}
                            setData={setData}
                            isNoComment={isNoComment}
                            groupConstant={groupConstant}
                          />
                        )
                      )}
                      {(kpi?.isEdited || user.role === USER_ROLE.Company) && (
                        <tr key="save-button">
                          <td colSpan={9} className="text-end">
                            <button
                              name={data.group}
                              className="btn btn-primary"
                              type="submit"
                            >
                              Save
                            </button>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
        {(kpi.isApproved || user.role === USER_ROLE.Company) && (
          <div className="d-flex mt-2 gap-2 justify-content-end mx-4">
            <button
              className="btn btn-tertiary"
              type="button"
              onClick={() => {
                approveForm({
                  formId,
                  type: "governance",
                  showAlert,
                  status: ApprovalEnums.Approved,
                  navigate,
                  goback: true,
                  year,
                });
              }}
            >
              Approve
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

function Row({
  data,
  sr,
  dropDownValues,
  setData,
  isNoComment,
  groupConstant,
}) {
  const calcuationHandler = useMemo(
    () => governanceCalculations(groupConstant),
    [groupConstant]
  );

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setData((prev) =>
      calcuationHandler(
        prev?.map((e) => {
          if (e.id === data.id) {
            return { ...e, [name]: value };
          }
          return e;
        })
      )
    );
  };
  const onCancelHandler = (file) => {
    setData((prev) =>
      prev?.map((e) => {
        if (e.id === data.id) {
          return { ...e, attachment: e.attachment.filter((item) => item !== file) };

        }
        return e;
      })
    );
  };
  const onFileUpload = (event) => {
    const { files } = event.target;
    setData((prev) =>
      prev?.map((e) => {
        if (e.id === data.id) {
          return { ...e, attachment: [...(e.attachment || []), ...files] };
        }
        return e;
      })
    );
  };
  return (
    <tr key={`${data?.fieldId}-table-row`}>
      <td>{sr}</td>
      <td>
        <span
          style={{
            display: "flex",
            gap: "5px",
          }}
        >
          {data?.fieldName}
          {data?.guidance && (
            <InfoIcon content={data.guidance} position="top" />
          )}
        </span>
      </td>

      <td>
        {Object.keys(dropDownValues || {})?.includes(data?.fieldName) ? (
          <select
            name="value"
            className="form-select"
            onChange={onChangeHandler}
            value={data?.value}
            required
          >
            <option value="">Select</option>
            {dropDownValues?.[data.fieldName]?.map((ele) => (
              <option value={ele.value}>{ele.value}</option>
            ))}
          </select>
        ) : groupConstant === "GovernanceParameters" ? (
          <textarea
            className="form-control"
            type="text"
            name="value"
            required
            value={data?.value}
            onChange={onChangeHandler}
            style={{
              padding: "5px 15px",
              fontSize: "14px",
              borderColor: "#e5e5e5",
              height: "5rem",
              width: "35rem",
              borderRadius: "8px",
            }}
          />
        ) : (
          <input
            className="form-control"
            type={
              [
                "Economic value distributed: operating costs, employee wages and benefits, payments to providers of capital, payments to government by country, and community investments",
                "Direct economic value generated: revenues",
              ].includes(data.fieldName)
                ? "number"
                : "text"
            }
            name="value"
            value={data?.value}
            onChange={onChangeHandler}
            readOnly={
              data.fieldName === "% of Economic Value Shared  Note: (ii/i)*100"
            }
            required
          />
        )}
      </td>
      {!isNoComment && (
        <td>
          <input
            className="form-control"
            type="text"
            name="comment"
            onChange={onChangeHandler}
            value={data?.comment}
          />
        </td>
      )}
      <td>
      <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          {/* Display uploaded files */}
          {data.attachment.length > 0 &&
            data.attachment.map((file, index) => {
              // Find the corresponding signed URL from 'url' array
              const fileUrlObj = data.url.find((item) => item.attachment === file);
              const fileUrl = fileUrlObj ? fileUrlObj.url : "#";
  
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "5px",
                  }}
                >
                  <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                    {typeof file === "string" ? decodeURIComponent(file.split("/").pop().split("?")[0]) : file.name}
                  </a>
                  <span
                    onClick={() => onCancelHandler(file)}
                    style={{
                      marginLeft: "10px",
                      cursor: "pointer",
                      border: "none",
                      padding: "2px 5px",
                      borderRadius: "50%",
                    }}
                  >
                    X
                  </span>
                </div>
              );
            })}
  
          {/* File input field */}
          <span style={{ position: "relative", width: "100%" }}>
            <div
              className="chooseFile"
              style={{
                position: "absolute",
                border: "1px solid #ccc",
                borderRadius: "4px",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                background: "#f9f9f9",
              }}
            >
              Choose Files
            </div>
            <input
              name="attachment"
              onChange={onFileUpload}
              multiple
              type="file"
              style={{
                opacity: 0,
                cursor: "pointer",
                zIndex: 1,
                top: 0,
                width: "100%",
                height: "100%",
              }}
              className="form-control w-100 h-100"
            />
          </span>
        </div>
        
      </td>
    </tr>
  );
}

function HeadRow({ label, sr, colSpan }) {
  return (
    <tr>
      <td>{sr}</td>
      <td colSpan={colSpan}>{label}</td>
    </tr>
  );
}

export default observer(GovernanceApproval);
