import { observer } from "mobx-react";
import { useMemo } from "react";
import { STATIC_GROUPS } from "../../../../../constants/constants";
import numToRoman from "../../../../../helpers/numToRoman";
import Row from "./row";
import { useLocation } from "react-router-dom";
import useStore from "../../../../../hooks/useStore";
import CustomTooltip from "../../../../../utils/tooltip/tooltip";
import info from "../../../../info.svg";
import { getRoundedValue } from "../../../../../utils/helpers";
import PermissbleLimit from "../../../../../components/permissbleLimit";

function FormTableBody({
  data,
  setData,
  frequency,
  editForm,
  setEditForm,
  allGroups,
}) {
  const {
    companyStore: { permissibleLimit },
  } = useStore();
  const { groupsFilled } = useLocation().state || {};
  const formatGroupData = useMemo(
    () =>
      Object.entries(
        data?.value?.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.group]: [...(acc[curr.group] ? acc[curr.group] : []), curr],
          }),
          {}
        )
      ).map(([key, value]) => ({
        group: key,
        label: STATIC_GROUPS.waste[key],
        value: value.sort((a, b) => Number(a.position) - Number(b.position)),
        target: value.reduce((acc, curr) => acc + Number(curr.target), 0),
        prevValue: value.reduce((acc, curr) => acc + Number(curr.prevValue), 0),
      })),
    [data?.value]
  );

  return (
    <tbody>
      {formatGroupData?.map((rows, index) => (
        <>
          {formatGroupData?.length > 1 && (
            <tr key={`${rows.group}-tr-sub-tr`}>
              <td>{numToRoman(index + 1)}</td>
              <td colSpan={7}>{rows.label}</td>
            </tr>
          )}
          {rows?.value?.map((rowData, index) => (
            <>
              {groupsFilled?.includes(data.group) && editForm !== data.group ? (
                <ShowDataRow
                  data={rowData}
                  id={formatGroupData?.length > 1 ? null : index}
                />
              ) : (
                <Row
                  data={rowData}
                  index={formatGroupData?.length > 1 ? null : index}
                  setData={setData}
                  group={data.group}
                  frequency={frequency}
                />
              )}
            </>
          ))}
          {formatGroupData?.length > 1 && (
            <RowTotal
              id={"asdfasacc"}
              data={rows}
              className="subheading-total"
            />
          )}
        </>
      ))}
      <RowTotal id={"asdfasacc"} data={data} />
      {["A", "D", "E"].includes(data.group) && (
        <PermissbleLimit
          // value={permissibleLimit?.find((e) => e.group === data.group)?.value}
          value={
            frequency?.frequencyType
              ? permissibleLimit?.find((e) => e.group === data.group)?.value || 0
              : 0
          }
          type={frequency?.frequencyType}
          date={frequency?.frequencyDate}
          unit={
            frequency?.frequencyType ?
            permissibleLimit?.find((e) => e.group === data.group)?.unitIdMaster
              ?.short_name
              :''
          }
        />
      )}
      {!groupsFilled?.includes(data.group) &&
      allGroups[groupsFilled?.length || 0] === data.group ? (
        <tr key="save-button">
          <td colSpan={9} className="text-end">
            <button name={data.group} className="btn btn-primary" type="submit">
              Save
            </button>
          </td>
        </tr>
      ) : !groupsFilled?.includes(data.group) ? (
        <tr>
          <td colSpan={8} className="text-end">
            <CustomTooltip
              content={
                "Please complete the previous form(s) to activate this button."
              }
              position="left"
              key={`${data.group}-disabled-key`}
            >
              <span
                style={{
                  width: "fit-content",
                  alignItems: "text-end",
                }}
              >
                <button
                  name={data.group}
                  className="btn btn-primary"
                  type="button"
                  disabled
                >
                  Save
                </button>
              </span>
            </CustomTooltip>
          </td>
        </tr>
      ) : null}
      {groupsFilled?.includes(data.group) && (
        <tr key="edit-button">
          <td colSpan={9} className="text-end">
            <button
              name={data.group}
              className="btn btn-primary"
              type={editForm === data.group ? "submit" : "button"}
              onClick={(e) => {
                if (editForm !== data.group) {
                  e.preventDefault();
                }
                setEditForm(data.group);
              }}
            >
              {editForm !== data.group ? "Edit" : "Save"}
            </button>
          </td>
        </tr>
      )}
    </tbody>
  );
}

function RowTotal({ id, data, className }) {
  return (
    <tr className={`subTotalTr ${id}-footer ${className}`}>
      <td>
        <b>{""}</b>
      </td>
      <td
        style={{
          fontWeight: "600",
        }}
      >
        Total
      </td>
      <td></td>
      <td></td>
      <td className="totalTd">{getRoundedValue(data?.prevValue)}</td>
      <td className="totalTd">{getRoundedValue(data?.target)}</td>
      <td></td>
      <td></td>
    </tr>
  );
}

function ShowDataRow({ data, id }) {
  const {
    companyStore: { units },
  } = useStore();
  return (
    <tr className="odd removeinput" key={id}>
      <td>{numToRoman(id + 1)}</td>
      <td>
        <span
          style={{
            display: "flex",
            gap: "5px",
          }}
        >
          {data?.fieldName}
          {data?.guidance && (
            <CustomTooltip position="top" content={data?.guidance}>
              <img src={info} alt="" />
            </CustomTooltip>
          )}
        </span>
      </td>
      <td>
        <span>
          {
            units?.find((ele) => ele.id === (data?.unitId || data?.unit))
              ?.shortName
          }
        </span>
      </td>

      <td>
        <div>{data?.frequencyType || ""}</div>
        <div>{data?.frequencyDate || data?.frequencyMonth || ""}</div>
        <div>{data?.frequencyYear || ""}</div>
      </td>

      <td className="text-center">
        <span>{getRoundedValue(data.prevValue)}</span>
      </td>
      <td className="text-center">
        <span>{getRoundedValue(data.target)}</span>
      </td>
      <td>
        <span>{data.comment}</span>
      </td>
      <td>
        {data?.attachment?.length > 0
          ? data.attachment.map((file, index) => (
            <div key={index}>{file.name.slice(0, 10) + "..."}</div>
          ))
          : ""}
      </td>
    </tr>
  );
}

export default observer(FormTableBody);
