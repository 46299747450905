import { observer } from "mobx-react";
import { Fragment, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import info from "../../../../info.svg";
import { STATIC_GROUPS } from "../../../../../constants/constants";
import Row from "./row";
import useStore from "../../../../../hooks/useStore";
import numToRoman from "../../../../../helpers/numToRoman";
import CustomTooltip from "../../../../../utils/tooltip/tooltip";
import {
  getRoundedValue,
  groupByConsecutiveGroups,
} from "../../../../../utils/helpers";
import { addGroupData } from "../../../../../helpers/groupDataCalculations";

function TableBody({
  data,
  setData,
  frequency,
  editForm,
  setEditForm,
  allGroups,
  isDeviated,
  groupData,
}) {
  const { groupsFilled } = useLocation().state || {};
  const values = useMemo(
    () =>
      (groupByConsecutiveGroups(data?.value) || [])?.map((value) => ({
        label: ["A", "B", "C", "D", "E", "F", "G", "H", "I"].includes(
          value[0].group
        )
          ? ""
          : STATIC_GROUPS.aqi[value[0].group],
        value,
        group: ["A", "B", "C", "D", "E", "F", "G", "H", "I"].includes(
          value[0].group
        )
          ? ""
          : value[0].group,
      })),
    [data?.value]
  );
  let id = 0;
  return (
    <tbody>
      {values?.map((ele, index) => {
        if (!ele.group) {
          return ele?.value?.map((e, i) =>
            groupsFilled?.includes(data.group) && editForm !== data.group ? (
              <ShowDataRow data={e} id={id++} />
            ) : (
              <>
                <Row
                  data={e}
                  index={id++}
                  setData={setData}
                  group={data.group}
                  frequency={frequency}
                  totalData={groupData}
                />
                {e.fieldName === "Reduction of GHG Emission in Scope -3" && (
                  <RowTotal
                    key="total-reduction"
                    data={addGroupData(
                      ele.value.filter(
                        (e) => e.fieldName !== "Reductions from offsets"
                      )
                    )}
                  />
                )}
                {e.fieldName === "Scope-3" && (
                  <RowTotal
                    key="total-reduction"
                    data={addGroupData(
                      ele.value.filter((e) =>
                        ["Scope-3", "Scope-2", "Scope-1"].includes(e.fieldName)
                      )
                    )}
                  />
                )}
              </>
            )
          );
        }
        return (
          <Fragment key={index + "fragment"}>
            <tr key={`${ele.group}-tr-sub-tr`}>
              <td>{numToRoman(id++ + 1)}</td>
              <td colSpan={7}>{ele.label}</td>
            </tr>
            {ele?.value?.map((e, i) =>
              groupsFilled?.includes(data.group) && editForm !== data.group ? (
                <ShowDataRow data={e} id={null} />
              ) : (
                <>
                  <Row
                    data={e}
                    index={null}
                    setData={setData}
                    group={data.group}
                    frequency={frequency}
                    totalData={groupData}
                  />
                </>
              )
            )}
            {ele.group !== "C1" && (
              <RowTotal
                key="row-total"
                data={
                  ele.group === "C2"
                    ? addGroupData(
                        values?.reduce(
                          (acc, curr) =>
                            curr.group === "C1" || curr.group === "C2"
                              ? [...acc, ...curr.value]
                              : [...acc],
                          []
                        )
                      )
                    : addGroupData(ele.value)
                }
              />
            )}
          </Fragment>
        );
      })}
      {["G", "I"].includes(data.group) && (
        <RowTotal
          key="row-total"
          data={addGroupData(data.value)}
          className={data.group === "I" && isDeviated ? "deviated" : ""}
        />
      )}
      {!groupsFilled?.includes(data.group) &&
      allGroups[groupsFilled?.length || 0] === data.group ? (
        <tr key="save-button">
          <td colSpan={9} className="text-end">
            <button name={data.group} className="btn btn-primary" type="submit">
              Save
            </button>
          </td>
        </tr>
      ) : !groupsFilled?.includes(data.group) ? (
        <tr>
          <td colSpan={8} className="text-end">
            <CustomTooltip
              content={
                "Please complete the previous form(s) to activate this button."
              }
              position="left"
              key={`${data.group}-disabled-key`}
            >
              <span
                style={{
                  width: "fit-content",
                  alignItems: "text-end",
                }}
              >
                <button
                  name={data.group}
                  className="btn btn-primary"
                  type="button"
                  disabled
                >
                  Save
                </button>
              </span>
            </CustomTooltip>
          </td>
        </tr>
      ) : null}
      {groupsFilled?.includes(data.group) && (
        <tr key="edit-button">
          <td colSpan={9} className="text-end">
            <button
              name={data.group}
              className="btn btn-primary"
              type={editForm === data.group ? "submit" : "button"}
              onClick={(e) => {
                if (editForm !== data.group) {
                  e.preventDefault();
                }
                setEditForm(data.group);
              }}
            >
              {editForm !== data.group ? "Edit" : "Save"}
            </button>
          </td>
        </tr>
      )}
    </tbody>
  );
}

function RowTotal({ id, data, className }) {
  return (
    <tr className={`subTotalTr ${id}-footer ${className}`}>
      <td>
        <b>{""}</b>
      </td>
      <td
        style={{
          fontWeight: "600",
        }}
      >
        Total
      </td>
      <td></td>
      <td></td>
      <td className="totalTd">{getRoundedValue(data?.prevValue)}</td>
      <td className="totalTd">{getRoundedValue(data?.target)}</td>
      <td></td>
      <td></td>
    </tr>
  );
}

function ShowDataRow({ data, id }) {
  const {
    companyStore: { units },
  } = useStore();
  return (
    <tr className="odd removeinput" key={id}>
      <td>{id ? numToRoman(id + 1) : "*"}</td>
      <td>
        <span
          style={{
            display: "flex",
            gap: "5px",
          }}
        >
          {data?.fieldName}
          {data?.guidance && (
            <CustomTooltip position="top" content={data?.guidance}>
              <img src={info} alt="" />
            </CustomTooltip>
          )}
        </span>
      </td>
      <td>
        <span>
          {
            units?.find((ele) => ele.id === (data?.unitId || data?.unit))
              ?.shortName
          }
        </span>
      </td>

      <td>
        <div>{data?.frequencyType || ""}</div>
        <div>{data?.frequencyDate || data?.frequencyMonth || ""}</div>
        <div>{data?.frequencyYear || ""}</div>
      </td>

      <td className="text-center">
        <span>{getRoundedValue(data.prevValue)}</span>
      </td>
      <td className="text-center">
        <span>{getRoundedValue(data.target)}</span>
      </td>
      <td>
        <span>{data.comment}</span>
      </td>
      <td>
        {data?.attachment?.length > 0
          ? data.attachment.map((file, index) => (
            <div key={index}>{file.name.slice(0, 10) + "..."}</div>
          ))
          : ""}
      </td>
    </tr>
  );
}

export default observer(TableBody);

/* {formatGroupData?.map((rows, index) => (
        <>
          {formatGroupData?.length > 1 &&
            !["A", "B", "C", "D"].includes(rows.group) && (
              <tr key={`${rows.group}-tr-sub-tr`}>
                <td>{numToRoman(index + 1)}</td>
                <td colSpan={7}>{rows.label}</td>
              </tr>
            )}
          {rows?.value?.map((rowData, index) => (
            <>
              {groupsFilled?.includes(data.group) && editForm !== data.group ? (
                <ShowDataRow
                  data={rowData}
                  id={formatGroupData?.length > 1 ? null : index}
                />
              ) : (
                <Row
                  data={rowData}
                  index={formatGroupData?.length > 1 ? null : index}
                  setData={setData}
                  group={data.group}
                  frequency={frequency}
                />
              )}
            </>
          ))}
          {formatGroupData?.length > 1 && (
            <RowTotal
              id={"asdfasacc"}
              data={rows}
              className="subheading-total"
            />
          )}
        </>
      ))} */
