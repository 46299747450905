import { action, computed, makeObservable, observable, toJS } from "mobx";
import fetchData from "../utils/fetchData";
import { addGroupData } from "../helpers/groupDataCalculations";

export default class WaterStore {
  waterForms = [];
  waterFormData = [];
  formId = null;
  formCreateError = null;
  formsSubmittedRaw = [];
  formDataRaw = [];
  formsRaw = [];
  constructor() {
    makeObservable(this, {
      waterForms: observable,
      waterFormData: observable,
      formId: observable,
      formCreateError: observable,
      formsSubmittedRaw: observable,
      formDataRaw: observable,
      formsRaw: observable,
      //computer
      waterFormsList: computed,
      waterFormDataList: computed,
      formsSubmitted: computed,
      formData: computed,
      forms: computed,
      //action
      fetchWaterForms: action.bound,
      fetchWaterFormData: action.bound,
      reset: action.bound,
      create: action.bound,
      setFormsSubmitted: action.bound,
      fetchFormData: action.bound,
      update: action.bound,
      setFormId: action.bound,
    });
  }

  get formsSubmitted() {
    return toJS(this.formsSubmittedRaw);
  }

  get waterFormsList() {
    return toJS(this.waterForms);
  }

  get waterFormDataList() {
    return toJS(this.waterFormData);
  }

  get formData() {
    return toJS(this.formDataRaw);
  }

  get forms() {
    return toJS(this.formsRaw);
  }

  reset() {
    this.waterFormData = [];
    this.waterForms = [];
    this.formId = null;
    this.formsSubmittedRaw = [];
    this.formDataRaw = [];
  }

  create({
    data,
    companyId,
    companyUnit,
    frequency,
    group,
    kpiId,
    subCatId,
    catId,
    showAlert,
    navigate,
    globalUnit,
    formsFilled,
    groupCDropdown,
  }) {
    const tempPayload = data?.find((e) => e.group === group)?.value;
    const payload = tempPayload?.map((e) => ({
      prevValue: Number(e.prevValue) || 0,
      target: Number(e.target) || 0,
      unit: Number(e.unit),
      companyId: Number(companyId),
      companyUnit: Number(companyUnit),
      fieldId: Number(e.fieldId),
      fieldGroup: group,
      value: e.value,
      groupName: e.group,
      comment: e.comment,
      attachment: e.attachment,
      groupCDropdown,
      ...frequency,
    }));

    const A = addGroupData(data?.find((e) => e.group === "A")?.value);
    const B = addGroupData(data?.find((e) => e.group === "A")?.value);

    const accumulatedValue =
      group === "C"
        ? {
            prevValue: A.prevValue - B.prevValue,
            target: A.target - B.target,
          }
        : addGroupData(tempPayload);
    const groupPayload = [
      {
        ...frequency,
        unitId: companyUnit,
        groupName: group,
        prevValue: accumulatedValue?.prevValue,
        targetValue: accumulatedValue?.target,
        kpiId,
        subCatId,
        catId,
        groupCDropdown,
      },
    ];
    const token = JSON.parse(
      localStorage.getItem("access-token") ||
        localStorage.getItem("admin-access-token")
    )?.token;
    const formData = new FormData();
    formData.append("data", JSON.stringify(payload));
    formData.append("groupPayload", JSON.stringify(groupPayload));
    payload.forEach((pay, index) => {
      if (pay?.attachment?.length) {
        for (let i = 0; i < pay.attachment.length; i++) {
          // Check if the attachment is an actual File object
          if (pay.attachment[i] instanceof File) {
            formData.append(`file[]`, pay.attachment[i]); // Append file
            formData.append(`index[]`, pay.fieldId); // Associate with fieldId
          } else {
            console.log(pay.attachment[i], "Skipping, as it's a file path");
          }
        }
      }
    });
    fetch(
      process.env.REACT_APP_BASE_URL +
        `add-water/create${this.formId ? `?formId=${this.formId}` : ""}`,
      {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          showAlert(response.message, true);
          this.formId = response.data.formId;
          this.formsSubmittedRaw = response.data.formsGroup;
          if (response.data.formsGroup.length === data.length) {
            navigate("/company/water");
            return;
          }
          navigate("/company/add-water", {
            state: {
              freq: frequency,
              formid: response.data.formId,
              formsFilled: [...formsFilled, group],
              formData: data,
              unitGlobal: globalUnit,
              groupCDropdown,
            },
          });

          return;
        }
        showAlert(response.message);
      })
      .catch((err) => {
        showAlert(err.message);
        console.error(err);
      });
  }

  update({
    data,
    companyId,
    companyUnit,
    frequency,
    group,
    kpiId,
    subCatId,
    catId,
    showAlert,
    formId,
    setEditForm,
  }) {
    const tempPayload = data?.find((e) => e.group === group)?.value;
    const payload = tempPayload?.map((e) => ({
      prevValue: Number(e.prevValue) || 0,
      target: Number(e.target) || 0,
      unit: Number(e.unit),
      companyId: Number(companyId),
      companyUnit: Number(companyUnit),
      fieldId: Number(e.fieldId),
      fieldGroup: group,
      value: e.value,
      groupName: e.group,
      comment: e.comment,
      attachment: e.attachment,
      position: e.position,
      ...frequency,
    }));

    const A = addGroupData(data?.find((e) => e.group === "A")?.value);
    const B = addGroupData(data?.find((e) => e.group === "A")?.value);

    const accumulatedValue =
      group === "C"
        ? {
            prevValue: A.prevValue - B.prevValue,
            target: A.target - B.target,
          }
        : addGroupData(tempPayload);
    const groupPayload = [
      {
        ...frequency,
        unitId: companyUnit,
        groupName: group,
        prevValue: accumulatedValue?.prevValue,
        targetValue: accumulatedValue?.target,
        kpiId,
        subCatId,
        catId,
      },
    ];
    const token = JSON.parse(
      localStorage.getItem("access-token") ||
        localStorage.getItem("admin-access-token")
    )?.token;
    const formData = new FormData();
    formData.append("data", JSON.stringify(payload));
    formData.append("groupPayload", JSON.stringify(groupPayload));
    payload?.forEach((pay, index) => {
      if (pay?.attachment?.length) {
        for (let i = 0; i < pay.attachment.length; i++) {
          // Check if the attachment is an actual File object
          if (pay.attachment[i] instanceof File) {
            formData.append(`file[]`, pay.attachment[i]); // Append file
            formData.append(`index[]`, pay.fieldId); // Associate with fieldId
          } else {
            console.log(pay.attachment[i], "Skipping, as it's a file path");
          }
        }
      }
    });
    fetch(
      process.env.REACT_APP_BASE_URL + `add-water/update?formId=${formId}`,
      {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.status) {
          showAlert(response.message, true);
          this.formId = response.data.formId;
          setEditForm("");
          this.fetchFormData({ formId: response.data.formId });
          return;
        }
        showAlert(response.message);
      })
      .catch((err) => {
        showAlert(err.message);
        console.error(err);
      });
  }

  fetchWaterForms(showAlert) {
    fetchData("add-water/getAllWaterForms", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          this.waterForms = data.data;
          return;
        }
        showAlert?.(data.message);
      })
      .catch((err) => {
        showAlert?.(err.message);
        console.error(err);
      });
  }

  fetchWaterFormData({ formId, showAlert }) {
    fetchData(`add-water/getFormData?formId=${formId}`, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          this.waterFormData = data.data;
          return;
        }
        showAlert?.(data.message);
      })
      .catch((err) => {
        showAlert?.(err.message);
        console.error(err);
      });
  }

  fetchFormData({ formId, showAlert }) {
    fetchData(`add-water/getFormData?formId=${formId}`, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          this.formDataRaw = data.data;
          return;
        }
        showAlert?.(data.message);
      })
      .catch((err) => {
        showAlert?.(err.message);
        console.error(err);
      });
  }

  setFormsSubmitted(payload) {
    this.formsSubmittedRaw = payload;
  }

  setFormId(id) {
    this.formId = id;
  }
}

WaterStore.prototype.fetchForms = function ({ showAlert }) {
  fetchData("add-water/getAllWaterForms", {
    method: "POST",
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) {
        this.formsRaw = data.data;
        return;
      }
      showAlert?.(data.message);
    })
    .catch((err) => {
      showAlert?.(err.message);
      console.error(err);
    });
};
