import { observer } from "mobx-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useStore from "../../../hooks/useStore";
import { useEffect, useMemo, useState } from "react";
import { findKpi, socialNestedData } from "../../../utils/helpers";
import {
  ApprovalEnums,
  NUMERIC_FIELDS,
  SMALL_GROUPS,
  SOCIAL_GROUP_CONSTANTS,
  USER_ROLE,
} from "../../../constants/constants";
import { Accordion, Form } from "react-bootstrap";
import InfoIcon from "../../../components/infoIcon";
import numToRoman from "../../../helpers/numToRoman";
import { useAuth } from "../../../context/authProvider";

const SocialApproval = () => {
  const navigate = useNavigate();
  const {
    state: { year, month, groupConstant },
  } = useLocation();
  const {
    social: { fetch, formData },
    companyStore: { kpiName },
    formStore: { updateFields, approveForm },
    userStore: { employeeKpis, user },
  } = useStore();
  const [expanded, setExpanded] = useState(0);
  const [data, setData] = useState([]);
  const { showAlert } = useAuth();

  useEffect(() => {
    if (year && month) {
      fetch({ year, month });
    }else{
      fetch({ year });
    }
  }, [fetch, month, year]);

  useEffect(() => {
    setData(formData);
  }, [formData]);

  const kpi = useMemo(
    () => findKpi({ kpis: employeeKpis, value: kpiName, field: "name" }),
    [employeeKpis, kpiName]
  );
  const formId = data?.[0]?.formId;

  return (
    <div className="add-water-page">
      <div className="card p-4">
        <Accordion
          className="water-table-accordion"
          defaultActiveKey={0}
          activeKey={expanded}
          onSelect={(eventKey) => {
            setExpanded(eventKey);
          }}
        >
          {socialNestedData(data, SOCIAL_GROUP_CONSTANTS[groupConstant])?.map(
            (accordion, index) => (
              <Accordion.Item
                key={`${accordion.group}-accordion-item`}
                eventKey={index}
              >
                <Accordion.Header>
                  <tr style={{ display: "flex", gap: "4px" }}>
                    <td>{index + 1}</td>
                    <td colSpan={4}>
                      {SOCIAL_GROUP_CONSTANTS[groupConstant][accordion.group]}
                    </td>
                  </tr>
                </Accordion.Header>
                <Accordion.Body>
                  <Form
                    noValidate
                    onSubmit={(event) => {
                      event.preventDefault();
                      if (event.target.checkValidity()) {
                        const payload = data?.filter((e) =>
                          e.group.startsWith(accordion.group)
                        );
                        updateFields({
                          data: payload,
                          type: "social",
                          formId,
                          showAlert,
                          year,
                          ...month && { month },
                        });
                        return;
                      }
                      showAlert("Please Fill All Fields");
                    }}
                  >
                    <table className="table align-middle table-bordered table-nowrap mb-0">
                      <thead>
                        <tr>
                          {[
                            "Sr. No.",
                            "Title",
                            "Value",
                            "Comment",
                            "Attachment",
                          ].map((head) => (
                            <th key={head}>{head}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {accordion?.value?.map((head, ind) =>
                          head?.label ? (
                            <>
                              <HeadRow
                                label={head.label}
                                sr={numToRoman(ind + 1)}
                                showAttachment
                                setData={setData}
                                data={data}
                                group={head.group}
                                attachment={
                                  data?.find((ele) =>
                                    ele?.group?.startsWith(head.group)
                                  )?.attachment
                                }
                                url={
                                  data?.find((ele) =>
                                    ele?.group?.startsWith(head.group)
                                  )?.url
                                }
                              />
                              {head?.value?.map((ele, i) =>
                                ele?.label ? (
                                  <>
                                    <HeadRow
                                      label={ele.label}
                                      sr={SMALL_GROUPS[i]}
                                    />
                                    {ele?.value?.map((e) => (
                                      <Row data={e} sr="*" setData={setData} />
                                    ))}
                                  </>
                                ) : (
                                  <Row
                                    data={ele}
                                    sr={SMALL_GROUPS[i]}
                                    setData={setData}
                                  />
                                )
                              )}
                            </>
                          ) : (
                            <Row
                              showAttachment
                              sr={ind + 1}
                              data={head}
                              setData={setData}
                              
                            />
                          )
                        )}
                        {(kpi?.isEdited || user.role === USER_ROLE.Company) && (
                          <tr key="save-button">
                            <td colSpan={9} className="text-end">
                              <button className="btn btn-primary" type="submit">
                                Save
                              </button>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </Form>
                </Accordion.Body>
              </Accordion.Item>
            )
          )}
        </Accordion>
        {(kpi.isApproved || user.role === USER_ROLE.Company) && (
          <div className="d-flex mt-2 gap-2 justify-content-end mx-4">
            <button
              className="btn btn-tertiary "
              type="button"
              onClick={() => {
                approveForm({
                  formId,
                  type: "social",
                  showAlert,
                  status: ApprovalEnums.Approved,
                  navigate,
                  goback: true,
                  year,
                  ...month && { month },
                  
                });
              }}
            >
              Approve
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

function Row({ data, sr, showAttachment, setData }) {
  const onChangeHandler = (event) => {
    const { value, name } = event.target;
    setData((prev) =>
      prev.map((e) => {
        if (e.id === data.id) {
          return { ...e, [name]: value };
        }
        return e;
      })
    );
  };
  const onFileUpload = (event) => {
    const files = Array.from(event.target.files);
    setData((prev) =>
      prev.map((e) => {
        if (e.id === data.id) {
          return { ...e, attachment: [...(e.attachment || []), ...files] };
        }
        return e;
      })
    );
  };
  const onCancelHandler = (file) => {
    setData((prev) =>
      prev.map((e) => {
        if (e.id === data.id) {
          return { ...e, attachment: e.attachment.filter((item) => item !== file) };
        }
        return e;
      })
    );
  };
  return (
    <tr key={`${data.fieldId}-row`}>
      <td>{sr}</td>
      <td>
        <span
          style={{
            display: "flex",
            gap: "5px",
          }}
        >
          {data?.fieldName}
          {data?.guidance && (
            <InfoIcon content={data?.guidance} position="top" />
          )}
        </span>
      </td>
      <td>
        <input
          type={NUMERIC_FIELDS.includes(data.fieldName) ? "number" : "text"}
          name="value"
          onChange={onChangeHandler}
          value={data.value}
          className="rounded form-control w-100 numberCountInput"
          required
        />
      </td>
      <td>
        <input
          type="text"
          name="comment"
          onChange={onChangeHandler}
          value={data.comment}
          className="rounded form-control w-100 numberCountInput"
        />
      </td>
      {showAttachment && (
        // <td>
        //   {data?.attachment ? (
        //     <span
        //       style={{
        //         display: "flex",
        //         width: "100%",
        //         alignItems: "center",
        //         justifyContent: "space-between",
        //       }}
        //       className="fileUploader"
        //     >
        //       <span>
        //         {data?.attachment?.name ? (
        //           `${data?.attachment?.name}...` || ""
        //         ) : data?.attachment ? (
        //           <Link to={data?.attachment}>Download</Link>
        //         ) : (
        //           ""
        //         )}
        //       </span>
        //       <span
        //         onClick={onCancelHandler}
        //         style={{
        //           fontWeight: "700",
        //           cursor: "pointer",
        //           fontSize: "16px",
        //           lineHeight: "20px",
        //         }}
        //         className="crossBtn"
        //       >
        //         X
        //       </span>
        //     </span>
        //   ) : (
        //     <span
        //       w-100
        //       style={{
        //         position: "relative",
        //       }}
        //     >
        //       <div
        //         className="chooseFile"
        //         style={{
        //           position: "absolute",
        //           border: "1px solid #ccc",
        //           borderRadius: "4px",
        //           width: "100%",
        //           height: "100%",
        //           display: "flex",
        //           alignItems: "center",
        //           justifyContent: "center",
        //         }}
        //       >
        //         Choose File
        //       </div>
        //       <input
        //         name="attachment"
        //         onChange={onFileUpload}
        //         type="file"
        //         style={{
        //           opacity: 0,
        //           cursor: "pointer",
        //           zIndex: 1,
        //           top: 0,
        //           width: "100%",
        //           height: "100%",
        //         }}
        //         className="form-control w-100 h-100"
        //       />
        //     </span>
        //   )}
        // </td>
        <td>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          {/* Display uploaded files */}
          {data?.attachment.length > 0 &&
            data?.attachment.map((file, index) => {
              // Find the corresponding signed URL from 'url' array
              const fileUrlObj = data.url.find((item) => item.attachment === file);
              const fileUrl = fileUrlObj ? fileUrlObj.url : "#";
  
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "5px",
                  }}
                >
                  <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                    {typeof file === "string" ? decodeURIComponent(file.split("/").pop().split("?")[0]) : file.name}
                  </a>
                  <span
                    onClick={() => onCancelHandler(file)}
                    style={{
                      marginLeft: "10px",
                      cursor: "pointer",
                      border: "none",
                      padding: "2px 5px",
                      borderRadius: "50%",
                    }}
                  >
                    X
                  </span>
                </div>
              );
            })}
  
          {/* File input field */}
          <span style={{ position: "relative", width: "100%" }}>
            <div
              className="chooseFile"
              style={{
                position: "absolute",
                border: "1px solid #ccc",
                borderRadius: "4px",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                background: "#f9f9f9",
              }}
            >
              Choose Files
            </div>
            <input
              name="attachment"
              onChange={onFileUpload}
              multiple
              type="file"
              style={{
                opacity: 0,
                cursor: "pointer",
                zIndex: 1,
                top: 0,
                width: "100%",
                height: "100%",
              }}
              className="form-control w-100 h-100"
            />
          </span>
        </div>
      </td>
      )}
    </tr>
  );
}

function HeadRow({ label, showAttachment, sr, attachment, setData, group, url,data }) {
  const onCancelHandler = (file) => {
    setData?.((prev) =>
      prev?.map((e) => {
        if (e?.group?.startsWith(group)) {
          return { ...e, attachment: e.attachment.filter((item) => item !== file) };
        }
        return e;
      })
    );
  };
  const onFileUpload = (event) => {
    const { files } = event.target;
    setData?.((prev) =>
      prev?.map((e) => {
        if (e?.group?.startsWith(group)) {
          return { ...e, attachment: [...(e.attachment || []), ...files] };
        }
        return e;
      })
    );
  };

  return (
    <tr key={label}>
      <td key="sr-no">{sr}</td>
      <td key={label} colSpan={showAttachment ? 3 : 4}>
        {["For Workers", "For Employees"].includes(label) ? (
          <b>{label}</b>
        ) : (
          label
        )}
      </td>
      {showAttachment && (
        <td>
           <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          {/* Display uploaded files */}
          {attachment.length > 0 &&
            attachment.map((file, index) => {
              // Find the corresponding signed URL from 'url' array
              const fileUrlObj = url.find((item) => item.attachment === file);
              const fileUrl = fileUrlObj ? fileUrlObj.url : "#";
  
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "5px",
                  }}
                >
                  <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                    {typeof file === "string" ? decodeURIComponent(file.split("/").pop().split("?")[0]) : file.name}
                  </a>
                  <span
                    onClick={() => onCancelHandler(file)}
                    style={{
                      marginLeft: "10px",
                      cursor: "pointer",
                      border: "none",
                      padding: "2px 5px",
                      borderRadius: "50%",
                    }}
                  >
                    X
                  </span>
                </div>
              );
            })}
  
          {/* File input field */}
          <span style={{ position: "relative", width: "100%" }}>
            <div
              className="chooseFile"
              style={{
                position: "absolute",
                border: "1px solid #ccc",
                borderRadius: "4px",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                background: "#f9f9f9",
              }}
            >
              Choose Files
            </div>
            <input
              name="attachment"
              onChange={onFileUpload}
              multiple
              type="file"
              style={{
                opacity: 0,
                cursor: "pointer",
                zIndex: 1,
                top: 0,
                width: "100%",
                height: "100%",
              }}
              className="form-control w-100 h-100"
            />
          </span>
        </div>
        </td>
      )}
    </tr>
  );
}

export default observer(SocialApproval);
