import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import useStore from "../../hooks/useStore";
import {
  ApprovalEnums,
  STATIC_GROUPS,
  USER_ROLE,
} from "../../constants/constants";
import { Accordion, Form } from "react-bootstrap";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useAuth } from "../../context/authProvider";
import fetchData from "../../utils/fetchData";
import CustomTooltip from "../../utils/tooltip/tooltip";
import info from "../../assets/icons/info.svg";
import { findKpi } from "../../utils/helpers";
import { Modal, Button } from "react-bootstrap";
import LogTableBody from "../company/logForms/besicLog";

const SRNO = ["a", "b", "c", "d", "e", "f", "g", "h", "i"];

const BASIC_TABLE_HEADERS = ["Sr. no", "Parameter", "Description"];

const generateYearArray = (length = 5) =>
  Array.from({ length }).map((_, index) => new Date().getFullYear() - index);

const TableHeaders = () => (
  <thead>
    <tr>
      {BASIC_TABLE_HEADERS.map((ele, index) => (
        <th style={{ fontWeight: "700" }} key={`${ele}-head`}>
          {ele}
        </th>
      ))}
    </tr>
  </thead>
);

const BasicParameterPage = () => {
  const navigate = useNavigate();
  const { showAlert } = useAuth();
  const {
    formStore: {
      fetchFields,
      fields,
      createBasicForm,
      approveForm,
      deleteForm,
      logRaw,
      logData,
    },
    companyStore: { companyUnit, kpiName },
    userStore: { employeeKpis, user },
  } = useStore();

  const { formid, groupsFilled, formData, formYear } =
    useLocation().state || {};

  const [data, setData] = useState([]);
  const [year, setYear] = useState(formYear || 0);
  const [validate, setValidate] = useState(false);
  const [editForm, setEditForm] = useState("");
  const [expanded, setExpanded] = useState(0);
  const [showFormData, setShowFormData] = useState([]);
  const [approvalStatus, setApprovalStatus] = useState("");
  const [formId, setFormId] = useState("");
  // State for modals
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(""); // "approve" or "delete"
  //calculations
  useEffect(() => {
    const fieldGroup = Object.entries(
      fields?.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.group]: [...(acc[curr.group] || []), { ...curr, value: "" }],
        }),
        {}
      )
    )?.map(([group, values]) => ({
      label: STATIC_GROUPS.basic[group],
      group: group,
      values,
    }));
    if (fields) {
      setData(fieldGroup);
    }
  }, [fields]);

  useEffect(() => {
    fetchFields();
  }, [fetchFields]);

  useEffect(() => {
    if (formData) {
      setData(formData);
    }
  }, [formData]);

  useEffect(() => {
    setExpanded(groupsFilled?.length || 0);
  }, [groupsFilled]);

  useEffect(() => {
    if (!formYear && year) {
      fetchData(`basic/formsFilled?year=${year}`)
        .then((res) => res.json())
        .then((res) => {
          if (res.status) {
            setApprovalStatus(res?.data?.[0]?.approvalStatus);
            setFormId(res?.data?.[0]?.formId);
            // setYear(res?.data?.[0]?.year);

            const fieldGroup = Object.entries(
              res?.data?.reduce((acc, curr) => {
                if (!curr.fieldName) {
                  return {
                    ...acc,
                    [curr.group]: [
                      ...(acc[curr.group] || []),
                      { ...curr, file: curr.attachment },
                    ],
                  };
                }
                return {
                  ...acc,
                  [curr.group]: [...(acc[curr.group] || []), curr],
                };
              }, {})
            )?.map(([group, values]) => ({
              label: STATIC_GROUPS.basic[group],
              group: group,
              values,
            }));
            fieldGroup?.sort((a, b) => a.group.localeCompare(b.group));

            setShowFormData(fieldGroup);
          }
        });
    }
  }, [formYear, year]);

  const kpi = useMemo(
    () => findKpi({ kpis: employeeKpis, value: kpiName, field: "name" }),
    [employeeKpis, kpiName]
  );

  const handleModalShow = (type) => {
    setModalType(type);
    setShowModal(true);
  };
  const handleModalLogShow = async (formId) => {
    // Fetch form log data
    await logData({ formId });
    setModalType("log");
    setShowModal(true);
  };
  const handleModalClose = () => {
    setShowModal(false);
    setModalType("");
  };

  const handleConfirmAction = () => {
    if (modalType === "approve") {
      approveForm({
        formId,
        type: "basic",
        showAlert,
        status: ApprovalEnums.Approved,
        navigate,
        // goback: true,
        year,
        kpiName,
      });
    } else if (modalType === "delete") {
      deleteForm({
        formId,
        type: "basic",
        showAlert,
        navigate,
        // goback: true,
        year,
        kpiName,
        status: ApprovalEnums.Pending,
      });
    }
    handleModalClose();
  };

  const formDataFirstIndex = showFormData[0]?.values[0];
  const approval = showFormData[0]?.values[0]?.approvalStatus;
  return (
    <div className="basic-list">
      <div className="container-fluid">
        {/* Page Title */}
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">Basic Parameter</h4>
              <div className="page-title-right"></div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="mb-3">
                  <label className="form-label">Year</label>
                  <select
                    required
                    className="form-select"
                    value={year}
                    disabled={Boolean(formYear)}
                    onChange={(event) => setYear(Number(event.target.value))}
                  >
                    <option value={0}>Select Year</option>
                    {generateYearArray().map((value, index) => (
                      <option key={index} value={value}>
                        {`FY${value}`}
                      </option>
                    ))}
                  </select>
                  {approval && Boolean(year) && (
                    <div className="table-responsive mt-3 approval-table">
                      <table className="table align-middle table-bordered table-nowrap mb-0  ">
                        <thead>
                          <th>Updated By</th>
                          <th className=" p-3">Status</th>
                          <th className=" p-3">Approved By:</th>
                          <th>Log</th>
                          <th>Action</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div>
                                <div>
                                  {formDataFirstIndex?.updater?.first_name ??
                                    "-"}{" "}
                                  {formDataFirstIndex?.updater?.last_name ??
                                    "-"}
                                </div>
                                {formDataFirstIndex?.updatedBy && (
                                  <div
                                    style={{ fontSize: "0.8em", color: "gray" }}
                                  >
                                    (
                                    {formDataFirstIndex?.updatedBy
                                      ? new Date(
                                        formDataFirstIndex?.updatedAt
                                      ).toLocaleDateString("en-GB")
                                      : "-"}
                                    )
                                  </div>
                                )}
                              </div>
                            </td>
                            <td>
                              {approval === "approved" ? "Approved" : "Pending"}{" "}
                            </td>

                            <td>
                              <div>
                                <div>
                                  {formDataFirstIndex?.approver?.first_name ??
                                    "-"}{" "}
                                  {formDataFirstIndex?.approver?.last_name ??
                                    "-"}
                                </div>
                                {formDataFirstIndex?.approvedAt && (
                                  <div
                                    style={{ fontSize: "0.8em", color: "gray" }}
                                  >
                                    (
                                    {formDataFirstIndex?.approvedAt
                                      ? new Date(
                                        formDataFirstIndex?.approvedAt
                                      ).toLocaleDateString("en-GB")
                                      : "-"}
                                    )
                                  </div>
                                )}
                              </div>
                            </td>
                            <td>
                              <i
                                type="button"
                                onClick={() => handleModalLogShow(formId)}
                                className="mdi mdi-dots-vertical"
                              ></i>
                            </td>
                            {approval !== "approved" ? (
                              <td>
                                <div className="d-flex mt-2 gap-2  justify-content-center">
                                  {kpi.isEdited && !kpi.isApproved && (
                                    <button
                                      className="btn btn-primary"
                                      type="button"
                                      onClick={() => {
                                        navigate("/company/approve/basic", {
                                          state: {
                                            year,
                                          },
                                        });
                                      }}
                                    >
                                      Edit
                                    </button>
                                  )}
                                  {(kpi.isApproved ||
                                    user.role === USER_ROLE.Company) && (
                                      <>
                                        <button
                                          className="btn btn-primary"
                                          type="button"
                                          onClick={() =>
                                            handleModalShow("approve")
                                          }
                                        >
                                          Approve
                                        </button>
                                        <button
                                          className="btn btn-primary"
                                          type="button"
                                          onClick={() => {
                                            navigate("/company/approve/basic", {
                                              state: {
                                                year,
                                              },
                                            });
                                          }}
                                        >
                                          Edit
                                        </button>
                                        <button
                                          className="btn btn-danger"
                                          type="button"
                                          onClick={() =>
                                            handleModalShow("delete")
                                          }
                                        >
                                          Delete
                                        </button>
                                      </>
                                    )}
                                </div>
                              </td>
                            ) : (
                              <td>-</td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>

                <Accordion
                  className="water-table-accordion"
                  defaultActiveKey={0}
                  activeKey={expanded}
                  onSelect={(eventKey) => {
                    setExpanded(eventKey);
                    setValidate(false);
                  }}
                >
                  {showFormData.length === 9
                    ? showFormData?.map((accordion, index) => (
                      <Accordion.Item key={accordion.label} eventKey={index}>
                        <Accordion.Header>
                          <tr style={{ display: "flex", gap: "4px" }}>
                            <td>{index + 1}</td>
                            <td colSpan={8}>{accordion.label}</td>
                          </tr>
                        </Accordion.Header>
                        <Accordion.Body>
                          <table className="table align-middle table-bordered table-nowrap mb-0">
                            <TableHeaders />
                            <tbody>
                              {accordion?.values
                                ?.filter((row) => row.fieldName)
                                .map((row, ind) => (
                                <ShowTableRow data={row} ind={ind} />
                              ))}

                              {accordion?.values
                                ?.filter((row) => !row.fieldName)
                                .map((row, ind) => (
                                <ShowTableRow data={row} ind={ind} />
                              ))}

                              {/* {accordion?.file && (
                                <tr className="text-end">
                                  <td colSpan={3}>
                                    <Link to={accordion.file} />
                                  </td>
                                </tr>
                              )} */}
                            </tbody>
                          </table>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))
                    : data?.map((accordion, index) => (
                      <Accordion.Item key={accordion.label} eventKey={index}>
                        <Accordion.Header>
                          <tr style={{ display: "flex", gap: "4px" }}>
                            <td>{index + 1}</td>
                            <td colSpan={8}>{accordion.label}</td>
                          </tr>
                        </Accordion.Header>
                        <Accordion.Body>
                          <Form
                            noValidate
                            validated={validate}
                            onSubmit={(event) => {
                              event.preventDefault();
                              if (year && event.target.checkValidity()) {
                                createBasicForm({
                                  data: accordion,
                                  fullData: data,
                                  companyUnit,
                                  year,
                                  showAlert,
                                  navigate,
                                  formId: formid,
                                  update: groupsFilled?.includes(
                                    accordion.group
                                  ),
                                  setEditForm,
                                });
                                return;
                              }
                              setValidate(true);
                              showAlert(
                                !year
                                  ? "Please select Year."
                                  : "Please fill the required fields."
                              );
                            }}
                          >
                            <table className="table align-middle table-bordered table-nowrap mb-0">
                              <TableHeaders />
                              <TableBody
                                data={accordion}
                                setData={setData}
                                editForm={editForm}
                                setEditForm={setEditForm}
                              />
                            </table>
                          </Form>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleModalClose}
        size={modalType === "log" ? "xl" : ""}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {modalType === "approve"
              ? "Approve Form"
              : modalType === "delete"
                ? "Delete Form"
                : "Log Details"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          {modalType === "approve" && (
            <p>Are you sure you want to approve this form?</p>
          )}
          {modalType === "delete" && (
            <p>
              Are you sure you want to delete this form? Once deleted, all data
              associated with this form will be permanently removed and cannot
              be recovered.
            </p>
          )}
          {modalType === "log" && (
            <div style={{ overflowX: "auto" }}>
              <table className="table table-responsive">
                <thead>
                  <tr key={12} className="waterTabletr">
                    <th key={0}>Username</th>
                    <th key={1}>Date</th>
                    <th key={2}>Action</th>
                    <th key={3}>Ip</th>
                    <th key={4}>Request</th>
                  </tr>
                </thead>
                <LogTableBody logRaw={logRaw} formId={formId} />
              </table>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Cancel
          </Button>
          {modalType === "approve" && (
            <Button variant="primary" onClick={handleConfirmAction}>
              Approve
            </Button>
          )}
          {modalType === "delete" && (
            <Button variant="primary" onClick={handleConfirmAction}>
              Delete
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

function TableBody({ data, setData, editForm, setEditForm }) {
  const { groupsFilled } = useLocation().state || {};
  const [selectedFiles, setSelectedFiles] = useState([]);

  // Handle file selection
  const onChangeHandler = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]); // Append new files

    setData((prev) =>
      prev?.map((ele) =>
        ele.group === data.group ? { ...ele, file: [...(ele.file || []), ...files] } : ele
      )
    );
  };

  // Remove selected file
  const cancelHandler = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));

    setData((prev) =>
      prev?.map((ele) =>
        ele.group === data.group
          ? { ...ele, file: ele.file.filter((_, i) => i !== index) }
          : ele
      )
    );
  };
  return (
    <tbody>
      {data.values.map((row, index) =>
        groupsFilled?.includes(data.group) && data.group !== editForm ? (
          <ShowTableRow data={row} ind={index} />
        ) : (
          <TableRow data={row} ind={index} setData={setData} />
        )
      )}
      <tr key={`attachment-${data.group}`}>
        <td colSpan={3}>
          
          {/* Display selected images */}
          {selectedFiles.map((file, index) => (
            <div key={index} style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
              <span>{file?.name.slice(0, 10) + "..."}</span>
              <button
                type="button"
                onClick={() => cancelHandler(index)}
                style={{
                  marginLeft: "10px",
                  cursor: "pointer",
                  border: "none",
                  padding: "2px 5px",
                  borderRadius: "50%",
                }}
              >
                X
              </button>
            </div>
          ))}
          <span
            w-100
            style={{
              position: "relative",
            }}
          >
            <div
              className="chooseFile"
              style={{
                position: "absolute",
                border: "1px solid #ccc",
                borderRadius: "4px",
                width: "20%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Choose File
            </div>
            <input
              onChange={onChangeHandler}
              type="file"
              multiple
              style={{
                opacity: 0,
                cursor: "pointer",
                zIndex: 1,
                top: 0,
                width: "50%",
                height: "100%",
              }}
              className="form-control"
            />
          </span>
        </td>
      </tr>
      {!groupsFilled?.includes(data.group) &&
        ["A", "B", "C", "D", "E", "F", "G", "H", "I"][groupsFilled?.length || 0] ===
        data.group ? (
        <tr key={`save--button-${data.group}`}>
          <td colSpan={3} className="text-end">
            <button className="btn btn-primary" type="submit" name={data.group}>
              Save
            </button>
          </td>
        </tr>
      ) : !groupsFilled?.includes(data.group) ? (
        <tr>
          <td colSpan={3} className="text-end">
            <CustomTooltip
              content={
                "Please complete the previous form(s) to activate this button."
              }
              position="left"
              key={`${data.group}-disabled-key`}
            >
              <span
                style={{
                  width: "fit-content",
                  alignItems: "text-end",
                }}
              >
                <button
                  name={data.group}
                  className="btn btn-primary"
                  type="button"
                  disabled
                >
                  Save
                </button>
              </span>
            </CustomTooltip>
          </td>
        </tr>
      ) : null}
      {groupsFilled?.includes(data.group) && (
        <tr key="edit-button">
          <td colSpan={9} className="text-end">
            <button
              name={data.group}
              className="btn btn-primary"
              type={editForm === data.group ? "submit" : "button"}
              onClick={(e) => {
                if (editForm !== data.group) {
                  e.preventDefault();
                }
                setEditForm(data.group);
              }}
            >
              {editForm !== data.group ? "Edit" : "Save"}
            </button>
          </td>
        </tr>
      )}
    </tbody>
  );
}

function TableRow({ data, ind, setData }) {
  const onChangeHandler = (event) => {
    setData((prev) =>
      prev.map((ele) => {
        if (ele.group === data.group) {
          const temp = ele.values.map((e) => {
            if (e.fieldId === data.fieldId) {
              return {
                ...e,
                ...(event.target.value?.split(" ")?.length - 1 <= 400
                  ? { value: event.target.value }
                  : 0),
              };
            }
            return e;
          });
          return { ...ele, values: temp };
        }
        return ele;
      })
    );
  };
  return (
    <tr key={`${ind}-${data.group}-tr`}>
      <td>{SRNO[ind]}</td>
      <td>
        <span
          style={{
            display: "flex",
            gap: "5px",
          }}
        >
          {data?.fieldName}
          {data?.guidance && (
            <CustomTooltip position="top" content={data?.guidance}>
              <img src={info} alt="" />
            </CustomTooltip>
          )}
        </span>
      </td>
      <td>
        <textarea
          value={data.value}
          style={{
            padding: "5px 15px",
            fontSize: "14px",
            borderColor: "#e5e5e5",
            height: "5rem",
            width: "35rem",
            borderRadius: "8px",
          }}
          required
          onChange={onChangeHandler}
        />
        <p>{`${data?.value?.split(" ").length - 1} of 400 word`} </p>
      </td>
    </tr>
  );
}

function ShowTableRow({ data, ind }) {
  return (
    <>
      {data.fieldName ? (
        <tr key={`${ind}-${data.group}-tr`}>
          <td>{SRNO[ind]}</td>
          <td>
            <span style={{ display: "flex", gap: "5px" }}>
              {data?.fieldName}
              {data?.guidance && (
                <CustomTooltip position="top" content={data?.guidance}>
                  <img src={info} alt="" />
                </CustomTooltip>
              )}
            </span>
          </td>
          <td>
            <p style={{ maxWidth: "650px" }}>{data.value}</p>
          </td>
        </tr>
      ) : (
        <tr key={`${ind}-${data.group}-attachment-tr`}>
          <td colSpan={3}>
            {data?.attachment && data.attachment.length > 0 ? (
              data.attachment.map((file, index) => {
                const fileUrlObj = data.url.find((item) => item.attachment === file);
                const fileUrl = fileUrlObj ? fileUrlObj.url : "#";

                return (
                  <div key={index} style={{ display: "inline-flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Link to={fileUrl} rel="noopener noreferrer" style={{ marginRight: "10px" }} >
                      Download {index + 1} 
                    </Link>
                  </div>
                );
              })
            ) : (
              <div>No Attachment</div>
            )}
          </td>
        </tr>
      )}
    </>
  );
}


export default observer(BasicParameterPage);
