import { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Accordion, Form } from "react-bootstrap";
import fetchData from "../../../utils/fetchData";
import {
  ApprovalEnums,
  SMALL_GROUPS,
  STATIC_GROUPS,
  USER_ROLE,
} from "../../../constants/constants";
import InfoIcon from "../../../components/infoIcon";
import useStore from "../../../hooks/useStore";
import { useAuth } from "../../../context/authProvider";
import { findKpi } from "../../../utils/helpers";

const BasicParameterApproval = () => {
  const {
    state: { year },
  } = useLocation();
  const {
    formStore: { approveForm, updateFields },
    companyStore: { kpiName },
    userStore: { employeeKpis, user },
  } = useStore();
  const navigate = useNavigate();
  const { showAlert } = useAuth();
  const [data, setData] = useState([]);
  const [expanded, setExpanded] = useState(0);
  const [, setValidate] = useState(false);
  useEffect(() => {
    fetchData(`basic/formsFilled?year=${year}`)
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {
          setData(res?.data);
        }
      });
  }, [year]);
  const formId = data?.[0]?.formId;

  const kpi = useMemo(
    () => findKpi({ kpis: employeeKpis, value: kpiName, field: "name" }),
    [employeeKpis, kpiName]
  );

  return (
    <div className="add-water-page">
      <div className="card p-4">
        <Accordion
          className="water-table-accordion"
          defaultActiveKey={0}
          activeKey={expanded}
          onSelect={(eventKey) => {
            setExpanded(eventKey);
            setValidate(false);
          }}
        >
          {convertToNested(data)?.map((accordion, index) => (
            <Accordion.Item key={accordion.label} eventKey={index}>
              <Accordion.Header>
                <tr style={{ display: "flex", gap: "4px" }}>
                  <td>{index + 1}</td>
                  <td colSpan={8}>{accordion.label}</td>
                </tr>
              </Accordion.Header>
              <Accordion.Body>
                <Form
                  noValidate
                  onSubmit={(event) => {
                    event.preventDefault();
                    if (event.target.checkValidity()) {
                      const payload = data?.filter(
                        (e) => e.group === accordion.group
                      );
                      updateFields({
                        data: payload,
                        type: "basic",
                        formId,
                        showAlert,
                        year,
                      });
                      return;
                    }
                    showAlert("Please Fill All Fields");
                  }}
                >
                  <table className="table align-middle table-bordered table-nowrap mb-0">
                    <thead>
                      <tr>
                        {["Sr. no", "Parameter", "Description"].map(
                          (ele, index) => (
                            <th
                              style={{ fontWeight: "700" }}
                              key={`${ele}-${index}-head`}
                            >
                              {ele}
                            </th>
                          )
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {accordion?.value?.map((item, ind) => (
                        <Row
                          key={item.id}
                          data={item}
                          sr={SMALL_GROUPS[ind]}
                          setData={setData}
                        />
                      ))}
                      {accordion?.value?.map((item, ind) => (
                        !item?.fieldName ? (
                          <Attachment
                            key={`attachment-${item.id}`} // Unique key for attachment
                            attachment={item?.attachment}
                            setData={setData}
                            group={item?.group}
                            url={item?.url}
                          />
                        ) : null
                      ))}
                      {(kpi?.isEdited || user.role === USER_ROLE.Company) && (
                        <tr key={`save--button-${data.group}`}>
                          <td colSpan={3} className="text-end">
                            <button
                              className="btn btn-primary"
                              type="submit"
                              name={data.group}
                            >
                              Save
                            </button>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
        {(kpi.isApproved || user.role === USER_ROLE.Company) && (
          <div className="d-flex mt-2 gap-2 justify-content-end mx-4 ">
            <button
              className="btn btn-tertiary"
              type="button"
              onClick={() => {
                approveForm({
                  formId,
                  type: "basic",
                  showAlert,
                  status: ApprovalEnums.Approved,
                  navigate,
                  goback: true,
                  year,

                });
              }}
            >
              Approve
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

function Row({ data, sr, setData }) {
  if (!data.fieldId) return null;

  const onChangeHandler = (event) => {
    const { value } = event.target;
    setData((prev) =>
      prev?.map((ele) => ({
        ...ele,
        value: ele.id === data.id ? value : ele.value,
      }))
    );
  };
  return (
    <tr key={`${data.group}-${data.fieldId}-tr`}>
      <td>{sr}</td>
      <td>
        <span
          style={{
            display: "flex",
            gap: "5px",
          }}
        >
          {data?.fieldName}
          {data?.guidance && (
            <InfoIcon position="top" content={data.guidance} />
          )}
        </span>
      </td>
      <td>
        <textarea
          value={data.value}
          style={{
            padding: "5px 15px",
            fontSize: "14px",
            borderColor: "#e5e5e5",
            height: "5rem",
            width: "35rem",
            borderRadius: "8px",
          }}
          required
          onChange={onChangeHandler}
        />
        <p>{`${data?.value?.split(" ").length - 1} of 400 words`}</p>
      </td>
    </tr>
  );
}



function Attachment({ attachment = [], setData, group, data, url }) {
  // const [selectedFiles, setSelectedFiles] = useState([]);

  // Remove specific file from the list
  const onCancelHandler = (file) => {
    setData((prev) =>
      prev.map((e) => {
        if (e.group === group && !e.fieldId) {
          return { ...e, attachment: e.attachment.filter((item) => item !== file) };
        }
        return e;
      })
    );
  };

  // Handle multiple file uploads
  const onFileUpload = (event) => {
    const files = Array.from(event.target.files);
    setData((prev) =>
      prev.map((e) => {
        if (e.group === group && !e.fieldId) {
          return { ...e, attachment: [...(e.attachment || []), ...files] };
        }
        return e;
      })
    );
    // setSelectedFiles((prev) => [...prev, ...files]);
  };

  return (
    <tr>
      <td colSpan={2}></td>
      <td>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          {/* Display uploaded files */}
          {attachment.length > 0 &&
            attachment.map((file, index) => {
              // Find the corresponding signed URL from 'url' array
              const fileUrlObj = url.find((item) => item.attachment === file);
              const fileUrl = fileUrlObj ? fileUrlObj.url : "#";
  
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "5px",
                  }}
                >
                  <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                    {typeof file === "string" ? decodeURIComponent(file.split("/").pop().split("?")[0]) : file.name}
                  </a>
                  <span
                    onClick={() => onCancelHandler(file)}
                    style={{
                      marginLeft: "10px",
                      cursor: "pointer",
                      border: "none",
                      padding: "2px 5px",
                      borderRadius: "50%",
                    }}
                  >
                    X
                  </span>
                </div>
              );
            })}
  
          {/* File input field */}
          <span style={{ position: "relative", width: "100%" }}>
            <div
              className="chooseFile"
              style={{
                position: "absolute",
                border: "1px solid #ccc",
                borderRadius: "4px",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                background: "#f9f9f9",
              }}
            >
              Choose Files
            </div>
            <input
              name="attachment"
              onChange={onFileUpload}
              multiple
              type="file"
              style={{
                opacity: 0,
                cursor: "pointer",
                zIndex: 1,
                top: 0,
                width: "100%",
                height: "100%",
              }}
              className="form-control w-100 h-100"
            />
          </span>
        </div>
      </td>
    </tr>
  );
  
}

function convertToNested(data) {
  const result = Object.entries(
    data?.reduce(
      (acc, curr) => ({
        ...acc,
        [curr?.group]: [...(acc?.[curr?.group] || []), curr],
      }),
      {}
    )
  )?.map(([group, value]) => ({
    group,
    value,
    label: STATIC_GROUPS.basic[group],
  }));
  result?.sort((a, b) => a.group.localeCompare(b.group));
  return result;
}

export default observer(BasicParameterApproval);
