import { observer } from "mobx-react";
import info from "../../../../info.svg";
import useStore from "../../../../../hooks/useStore";
import numToRoman from "../../../../../helpers/numToRoman";
import CustomTooltip from "../../../../../utils/tooltip/tooltip";
import { getUnits } from "../../../../../utils/helpers";

const accumulateResult = (data, key) =>
  data.reduce((acc, curr) => acc + (Number(curr[key]) || 0), 0);

const Row = ({ data, index, frequency, setData, group }) => {
  const {
    companyStore: { units },
  } = useStore();
  const onChangeHandler = (event) => {
    const { name, value, files } = event.target;
    setData((prev) =>
      prev.map((ele) => {
        if (ele.group === group) {
          const temp = ele.value.map((e) => {
            if (e.fieldId === data.fieldId) {
              return {
                ...e,
                ...frequency,
                [name]:
                  name === "attachment"
                    ? [...(e.attachment || []), ...Array.from(files || [])] // Properly convert FileList to an array
                    : value,
              };
            }
            return e;
          });
          return {
            ...ele,
            value: temp,
            target: accumulateResult(temp, "target"),
            prevValue: accumulateResult(temp, "prevValue"),
          };
        }
        return ele;
      })
    );
  };

  const cancelHandler = (file) => {
    setData((prev) =>
      prev.map((ele) => {
        if (ele.group === group) {
          const temp = ele.value.map((e) => {
            if (e.fieldId === data.fieldId) {
                const updatedAttachments = e.attachment.filter(
                  (item) => item.name !== file.name
                );
                return {
                  ...e,
                  ...frequency,
                  attachment: updatedAttachments,
                };
            }
            return e;
          });
          return {
            ...ele,
            value: temp,
          };
        }
        return ele;
      })
    );
  };

  return (
    <tr key={`${data?.id}-data-row`}>
      <td>{index ? numToRoman(index + 1) : "*"}</td>
      <td>
        <span
          style={{
            display: "flex",
            gap: "5px",
          }}
        >
          {data?.fieldName}
          {data?.guidance && (
            <CustomTooltip position="top" content={data?.guidance}>
              <img src={info} alt="" />
            </CustomTooltip>
          )}
        </span>
      </td>
      <td>
        <select
          className="form-select"
          name="unit"
          //   required={validate && !data.unit}
          required
          onChange={onChangeHandler}
          value={data?.unitId || data.unit}
          style={{ width: "135px" }}
        >
          <option value="">Select Unit</option>
          {getUnits({ units, data, type: "waste" })?.map((ele) => (
            <option key={ele.id} value={ele.id}>
              {ele?.shortName}
            </option>
          ))}
        </select>
      </td>
      <td>
        <div>{frequency?.frequencyType || ""}</div>
        <div>{frequency?.frequencyYear || ""}</div>
        <div>{frequency?.frequencyDate || frequency?.frequencyMonth || ""}</div>
      </td>
      <td>
        <input
          //   disabled={isField}
          type="number"
          name="prevValue"
          onChange={onChangeHandler}
          value={data.prevValue}
          className="rounded form-control w-100 numberCountInput"
        />
      </td>
      <td>
        <input
          //   disabled={isField}
          type="number"
          name="target"
          onChange={onChangeHandler}
          value={data.target}
          className="rounded form-control w-100 numberCountInput"
        />
      </td>
      <td>
        <textarea
          name="comment"
          value={data.comment}
          onChange={onChangeHandler}
          className="rounded form-control w-100 addFromTxtArea"
        />
      </td>
      {/* <td>
        <input
          name="value"
          value={data.value}
          onChange={onChangeHandler}
          className="rounded form-control w-100"
        />
      </td> */}
      <td>
        {data.attachment && data.attachment.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            {data.attachment.map((file, idx) => (
              <div
                key={idx}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                className="fileUploader"
              >
                <span>{file?.name.slice(0, 10) + "..."}</span>
                <span
                  onClick={() => cancelHandler(file)}
                  style={{
                    fontWeight: "700",
                    cursor: "pointer",
                    fontSize: "16px",
                    lineHeight: "20px",
                  }}
                  className="crossBtn"
                >
                  X
                </span>
              </div>
            ))}
          </div>
        )}
          <span
            w-100
            style={{
              position: "relative",
            }}
          >
            <div
              className="chooseFile"
              style={{
                position: "absolute",
                border: "1px solid #ccc",
                borderRadius: "4px",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Choose File
            </div>
            <input
              name="attachment"
              onChange={onChangeHandler}
              type="file"
              multiple
              style={{
                opacity: 0,
                cursor: "pointer",
                zIndex: 1,
                top: 0,
                width: "100%",
                height: "100%",
              }}
              className="form-control w-100 h-100"
            />
          </span>
       
      </td>
    </tr>
  );
};

export default observer(Row);
