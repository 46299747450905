import { observer } from "mobx-react";
import { useMemo, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getRoundedValue,
  groupByConsecutiveGroups,
} from "../../../../utils/helpers";
import { GROUPS, STATIC_GROUPS } from "../../../../constants/constants";
import CustomTooltip from "../../../../utils/tooltip/tooltip";
import numToRoman from "../../../../helpers/numToRoman";
import useStore from "../../../../hooks/useStore";
import info from "../../../info.svg";
import Row from "./row";
import { addGroupData } from "../../../../helpers/groupDataCalculations";
import PermissbleLimit from "../../../../components/permissbleLimit";
import { useAuth } from "../../../../context/authProvider";

const TableBody = ({
  data,
  setData,
  frequency,
  editForm,
  setEditForm,
  allGroups,
  groupData,
  globalUnit,
  groupCDropdownData,
  skipped,
  setSkipped,
}) => {
  const navigate = useNavigate();
  const { showAlert } = useAuth();
  const {
    companyStore: { permissibleLimit },
  } = useStore();
  const { groupsFilled, formid } = useLocation().state || {};
  const values = useMemo(
    () =>
      (groupByConsecutiveGroups(data?.value) || [])?.map((value) => ({
        label: GROUPS.includes(value[0].group)
          ? ""
          : STATIC_GROUPS.water[value[0].group],
        value,
        group: GROUPS.includes(value[0].group) ? "" : value[0].group,
      })),
    [data?.value]
  );

  const formsToBeEditable = skipped
    ? groupsFilled?.filter((e) => ![].includes(e))
    : groupsFilled;

  const A = addGroupData(groupData?.find((e) => e.group === "A")?.value);
  const B = addGroupData(groupData?.find((e) => e.group === "B")?.value);
  // const C = addGroupData(groupData?.find((e) => e.group === "C")?.value);
  const C = addGroupData(
    groupData
      ?.find((ele) => ele.group === "C")
      ?.value.filter(
        (e) =>
          ["Total Fresh Water Consumption : Plant", "Recycled Water consumption:"].includes(
            e.fieldName
          )
      )
  );

  const val = {
    prevValue: A?.prevValue,
    target: A?.target,
  };
  const isDeviated = C?.prevValue > val.prevValue || C?.target > val.target;


  let id = 0;

  return (

    <tbody>


      {values?.map((ele, index) => {
        if (!ele.group) {
          return ele?.value?.map((e, i) =>
            groupsFilled?.includes(data.group) && editForm !== data.group ? (
              <ShowDataRow data={e} id={id++} />
            ) : (
              <Row
                data={e}
                index={id++}
                setData={setData}
                group={data.group}
                frequency={frequency}
                totalData={groupData}

              />
            )
          );
        }
        return (
          <>
            <tr key={`${ele.group}-tr-sub-tr`}>
              <td>{numToRoman(id++ + 1)}</td>
              <td colSpan={7}>{ele.label}</td>
            </tr>
            {ele?.value?.map((e, i) =>
              groupsFilled?.includes(data.group) && editForm !== data.group ? (
                <ShowDataRow data={e} id={null} />
              ) : (
                <>

                  <Row
                    data={e}
                    index={null}
                    setData={setData}
                    group={data.group}
                    frequency={frequency}
                    totalData={groupData}
                  />
                </>
              )
            )}
          </>
        );
      })}
      {!["D", "E"].includes(data.group) && (
        <>
          {["A", "B"].includes(data.group) && (
            <RowTotal
              data={addGroupData(
                data?.value.filter((e) => e.group === data.group)

              )}
              text=" "
            />
          )}

          {data.group === "C" && (
            <RowTotal
              data={addGroupData(
                groupData?.find((e) => e.group === "C")?.value.filter((e) =>
                  [
                    "Total Fresh Water Consumption : Plant",
                    "Recycled Water consumption:",
                  ].includes(e.fieldName)
                )
              )}
              className={isDeviated && data.group === "C" ? "deviated" : ""}
              text="(I + II)"
            />
          )}
        </>
      )}
      {/* {["A", "B", "C"].includes(data.group) && (
        <PermissbleLimit
          value={permissibleLimit?.find((e) => e.group === data.group)?.value}
          type={frequency?.frequencyType}
          date={frequency?.frequencyDate}
          unit={
            frequency?.frequencyType ?
            permissibleLimit?.find((e) => e.group === data.group)?.unitIdMaster
              ?.short_name
              :''
          }
        />
      )} */}
      {["A", "B", "C"].includes(data.group) && (
        <PermissbleLimit
          // value={permissibleLimit?.find((e) => e.group === data.group)?.value}
          value={
            frequency?.frequencyType
              ? permissibleLimit?.find((e) => e.group === data.group)?.value ||
              0
              : 0
          }
          type={frequency?.frequencyType}
          date={frequency?.frequencyDate}
          unit={
            frequency?.frequencyType
              ? permissibleLimit?.find((e) => e.group === data.group)
                ?.unitIdMaster?.short_name
              : ""
          }
        />
      )}
      {!groupsFilled?.includes(data.group) &&
        GROUPS[groupsFilled?.length || 0] === data.group ? (
        <tr>
          <td
            colSpan={
              ["I"].includes(data.group) ? 7 : data.group === "D" ? 5 : 8
            }
            className="text-end"
          >
            <button name={data.group} className="btn btn-primary" type="submit">
              Save
            </button>
          </td>
          {data.group === "I" && (
            <td colSpan={1} className="text-end">
              <button
                name={data.group}
                className="btn btn-secondary"
                type="button"
                onClick={() => {
                  navigate("/company/water");
                  showAlert("Form filled Successfully.", true);
                }}
              >
                Skip and Save
              </button>
            </td>
          )}
          {[].includes(data.group) && (
            <td colSpan={1} className="text-end">
              <button
                name={data.group}
                className="btn btn-secondary"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setSkipped(true);
                  navigate("/company/add-water", {
                    state: {
                      freq: frequency,
                      formid,
                      groupsFilled: [...(groupsFilled || []), data.group],
                      formData: groupData,
                      unitGlobal: globalUnit,
                      skipped: true,
                    },
                  });
                }}
              >
                Skip and Save
              </button>
            </td>
          )}
        </tr>
      ) : !groupsFilled?.includes(data.group) ? (
        <tr>
          <td colSpan={8} className="text-end">
            <CustomTooltip
              content={
                "Please complete the previous form(s) to activate this button."
              }
              position="top"
              key={`${data.group}-disabled-key`}
            >
              <span
                style={{
                  width: "fit-content",
                  alignItems: "text-end",
                }}
              >
                <button
                  name={data.group}
                  className="btn btn-primary"
                  type="button"
                  disabled
                >
                  Save
                </button>
              </span>
            </CustomTooltip>
          </td>
        </tr>
      ) : null}

      {formsToBeEditable?.includes(data.group) && (
        <tr>
          <td colSpan={9} className="text-end">
            <button
              name={data.group}
              className="btn btn-primary"
              type={editForm === data.group ? "submit" : "button"}
              onClick={(e) => {
                if (editForm !== data.group) {
                  e.preventDefault();
                }
                setEditForm(data.group);
              }}
            >
              {editForm !== data.group ? "Edit" : "Save"}
            </button>
          </td>
        </tr>
      )}
    </tbody>
  );
};

function ShowDataRow({ data, id }) {
  const {
    companyStore: { units },
  } = useStore();
  return (
    <>

      <tr className="odd removeinput" key={id}>
        <td>{numToRoman(id + 1)}</td>
        <td>
          <span
            style={{
              display: "flex",
              gap: "5px",
            }}
          >
            {data?.fieldName}
            {data?.guidance && (
              <CustomTooltip position="top" content={data?.guidance}>
                <img src={info} alt="" />
              </CustomTooltip>
            )}
          </span>
        </td>
        <td>
          <span>
            {
              units?.find((ele) => ele.id === (data?.unitId || data?.unit))
                ?.shortName
            }
          </span>
        </td>

        <td>
          <div>{data?.frequencyType || ""}</div>
          <div>{data?.frequencyDate || data?.frequencyMonth || ""}</div>
          <div>{data?.frequencyYear || ""}</div>
        </td>

        <td className="text-center">
          <span>{getRoundedValue(data.prevValue)}</span>
        </td>
        {data.group !== "D" && (
          <>
            <td className="text-center">
              <span>{getRoundedValue(data.target)}</span>
            </td>
            <td>
              <span>{data.comment}</span>
            </td>
            <td>
              {data?.attachment?.length > 0
                ? data.attachment.map((file, index) => (
                  <div key={index}>{file.name.slice(0, 10) + "..."}</div>
                ))
                : ""}
            </td>
          </>
        )}
      </tr>
    </>
  );
}

function RowTotal({ id, data, className, text }) {
  return (
    <tr className={`subTotalTr ${id}-footer ${className}`}>
      <td>
        <b>{""}</b>
      </td>
      <td
        style={{
          fontWeight: "600",
        }}
      >
        Total {text}
      </td>
      <td></td>
      <td></td>
      <td className="totalTd">{getRoundedValue(data?.prevValue)}</td>
      <td className="totalTd">{getRoundedValue(data?.target)}</td>
      <td></td>
      <td></td>
    </tr>
  );
}


export default observer(TableBody);
