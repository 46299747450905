import { observer } from "mobx-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useStore from "../../../hooks/useStore";
import { useEffect, useMemo, useState } from "react";
import {
  ApprovalEnums,
  NON_EDITABLE_FIELDS,
  STATIC_GROUPS,
  USER_ROLE,
} from "../../../constants/constants";
import { Accordion, Form } from "react-bootstrap";
import {
  convertToNestedFormatEnv,
  findKpi,
  getApprovalUnits,
} from "../../../utils/helpers";
import InfoIcon from "../../../components/infoIcon";
import numToRoman from "../../../helpers/numToRoman";
import { environmentCalculationHandler } from "../../../helpers/groupDataCalculations";
import { useAuth } from "../../../context/authProvider";

const EnironmentApproval = () => {
  const {
    state: { formId, groupConstant, type, kpiName },
  } = useLocation();
  const navigate = useNavigate();
  const { showAlert } = useAuth();
  const GROUP_NAME = STATIC_GROUPS[groupConstant];
  console.log("groupConstant", GROUP_NAME);
  const {
    formStore: {
      fetchApprovalFields,
      approvalFields,
      updateFields,
      approveForm,
    },
    companyStore: { fetchUnits },
    userStore: { employeeKpis, user },
  } = useStore();
  const [data, setData] = useState([]);
  const [expanded, setExpanded] = useState(0);
  const [groupCDropdown, setGroupCDropdown] = useState(0);

  useEffect(() => {
    fetchApprovalFields({ formId, type });
  }, [fetchApprovalFields, formId, type]);

  useEffect(() => {
    fetchUnits();
  }, [fetchUnits]);

  useEffect(() => {
    setData(
      approvalFields.sort((a, b) => Number(a?.position) - Number(b?.position))
    );
  }, [approvalFields]);

  const kpi = useMemo(
    () => findKpi({ kpis: employeeKpis, value: kpiName, field: "name" }),
    [employeeKpis, kpiName]
  );

  const renderedData = convertToNestedFormatEnv({ data, label: GROUP_NAME });
  return (
    <div className="add-water-page">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0 font-size-18 text-capitalize">
              {type} Management
            </h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item text-capitalize">
                  <Link to="/company/energy">{type} List</Link>
                </li>
                <li className="breadcrumb-item text-capitalize active">
                  {type} Management
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="card p-4">
        <Accordion
          className="water-table-accordion"
          defaultActiveKey={0}
          activeKey={expanded}
          onSelect={(eventKey) => {
            setExpanded(eventKey);
          }}
        >
          <div className="table-responsive ">
            {renderedData?.map((item, index) => (
              <Accordion.Item
                key={`${item.group}-accordion-item`}
                eventKey={index}
              >
                <Accordion.Header>
                  <tr style={{ display: "flex", gap: "4px" }}>
                    <td>{index + 1}</td>
                    <td colSpan={7}>{item.label}</td>
                  </tr>
                </Accordion.Header>
                <Accordion.Body>
                  {["B"].includes(item.group) && type === 'water' && (
                    <RowDropdown
                      groupCDropdownData={groupCDropdown}
                      setGroupCDropdown={setGroupCDropdown}
                      group={item.group}
                      type={type}
                    />
                  )}

                  <Form
                    onSubmit={(event) => {
                      event.preventDefault();
                      if (event.target.checkValidity()) {

                        if (type === "water" && groupCDropdown) {
                          const payload = data
                            ?.filter((e) => e?.group?.includes(item.group))
                            .map((ele) => ({
                              ...ele,
                              groupCDropdown: groupCDropdown,
                            }));
                          updateFields({
                            data: payload,
                            type,
                            formId,
                            showAlert,
                          });
                        } else {
                          const payload = data?.filter(
                            (e) => e?.group?.includes(item.group)
                          );
                          updateFields({
                            data: payload,
                            type,
                            formId,
                            showAlert,
                          });
                        }


                        return;
                      }
                      showAlert("Please Fill All Fields");
                    }}
                  >
                    <table className="table align-middle table-bordered table-nowrap mb-0">
                      <TableHeader
                        isWater={type === "water"}
                        group={item.group}
                      />
                      <tbody>
                        {item?.value?.map((ele, i) =>
                          ele.label ? (
                            <>
                              <tr>
                                <td>{numToRoman(i + 1)}</td>
                                <td colSpan={7}>{ele.label}</td>
                              </tr>
                              {ele?.value?.map((e) => (
                                <Row
                                  data={e}
                                  sr="*"
                                  key={`-item-${i}-${e.id}`}
                                  totalData={data}
                                  setData={setData}
                                  calculationHandler={environmentCalculationHandler(
                                    type
                                  )}
                                />
                              ))}
                            </>
                          ) : (
                            <Row
                              data={ele}
                              sr={numToRoman(i + 1)}
                              totalData={data}
                              setData={setData}
                              calculationHandler={environmentCalculationHandler(
                                type
                              )}
                            />
                          )
                        )}
                        {(kpi?.isEdited || user.role === USER_ROLE.Company) && (
                          <tr key="save-button">
                            <td colSpan={9} className="text-end">
                              <button
                                name={data.group}
                                className="btn btn-primary"
                                type="submit"
                              >
                                Save
                              </button>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </Form>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </div>
        </Accordion>
        {(kpi?.isApproved || user.role === USER_ROLE.Company) && (
          <div className="d-flex mt-2 gap-2 justify-content-end mx-4">
            <button
              className="btn btn-tertiary "
              type="button"
              onClick={() => {
                approveForm({
                  formId,
                  type,
                  showAlert,
                  status: ApprovalEnums.Approved,
                  navigate,
                });
              }}
            >
              Approve
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const TableHeader = ({ isWater, group }) => (
  <thead>
    <tr key={12} className="waterTabletr">
      <th key={0}>Sr. No.</th>
      <th key={1}>Title</th>
      <th key={2}>Unit</th>
      <th key={3}>Frequency</th>
      <th key={4}>Current Value</th>
      <th key={5}>Target Value</th>
      <th key={6}>Comment</th>
      <th key={8}>Attachment</th>
    </tr>
  </thead>
);

const Row = ({ data, sr, isWater, totalData, setData, calculationHandler }) => {

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setData((prev) =>
      calculationHandler(
        prev.map((ele) =>
          ele.id.toString() === data.id.toString() ? { ...ele, [name]: value } : ele
        )
      )
    );
  };

  const {
    companyStore: { units },
  } = useStore();

  const { type } = useLocation().state;

  // const handleFileUpload = (event) => {
  //   const files = Array.from(event.target.files);
  //   // Update the attachment with the new files, preserving the old ones.
  //   setData((prev) =>
  //     prev.map((ele) =>
  //       ele.id.toString() === data.id.toString()
  //         ? { ...ele, attachment: [...(ele.attachment || []), ...files] }
  //         : ele
  //     )
  //   );
  // };

  // const removeAttachment = (index) => {
  //   setData((prev) =>
  //     prev.map((ele) =>
  //       ele.id.toString() === data.id.toString()
  //         ? { ...ele, attachment: ele.attachment.filter((_, i) => i !== index) }
  //         : ele
  //     )
  //   );
  // };


  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    setData((prev) =>
      prev.map((ele) =>
        ele.id.toString() === data.id.toString()
          ? { ...ele, attachment: [...(ele.attachment || []), ...files] }
          : ele
      )
    );
  };

  const removeAttachment = (index) => {
    setData((prev) =>
      prev.map((ele) =>
        ele.id.toString() === data.id.toString()
          ? {
            ...ele,
            attachment: ele.attachment.filter((_, i) => i !== index),
          }
          : ele
      )
    );
  };

  // useEffect(() => {
  //   console.log('After removal:', data);
  // }, [data]);

  const dataUnits = getApprovalUnits({ data, units, type, totalData });

  const isDisabled = useMemo(() => NON_EDITABLE_FIELDS.includes(data?.fieldName), [
    data?.fieldName,
  ]);

  // Process and handle attachments to create download links and display existing files properly
  const renderAttachments = () => {
    return data.attachment?.map((file, index) => {
      // If the file is a string (existing file path), use that URL
      const fileUrlObj = data.url.find((item) => item.attachment === file);
      const fileUrl = fileUrlObj ? fileUrlObj.url : "#";
      return (
        <div
          key={index}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: "#f8f9fa",
            padding: "5px",
            borderRadius: "4px",
          }}
        >
          <a href={fileUrl} target="_blank" rel="noopener noreferrer">
            {typeof file === "string" ? decodeURIComponent(file.split("/").pop().split("?")[0]) : file.name}
          </a>
          <span
            onClick={() => removeAttachment(index)}
            style={{ fontWeight: "700", cursor: "pointer", fontSize: "16px" }}
            className="crossBtn"
          >
            X
          </span>
        </div>
      );
    });
  };

  return (
    <tr key={`${data?.id}-data-row`}>
      <td>{sr}</td>
      <td>
        <span style={{ display: "flex", gap: "5px" }}>
          {data?.fieldName}
          {data?.guidance && <InfoIcon position="top" content={data?.guidance} />}
        </span>
      </td>
      <td>
        <select
          className="form-select"
          name="unit"
          required
          onChange={onChangeHandler}
          value={data?.unitId || data.unit}
          style={{ width: "135px" }}
        >
          <option value="">Select Unit</option>
          {dataUnits?.map((ele) => (
            <option key={ele.id} value={ele.id}>
              {ele?.shortName}
            </option>
          ))}
        </select>
      </td>
      <td>
        <div>{data?.frequencyType || ""}</div>
        <div>{data?.frequencyYear || ""}</div>
        <div>{data?.frequencyDate || data?.frequencyMonth || ""}</div>
      </td>
      <td>
        <input
          disabled={isDisabled}
          type="number"
          name="prevValue"
          onChange={onChangeHandler}
          value={data.prevValue}
          className="rounded form-control w-100 numberCountInput"
        />
      </td>
      <td>
        <input
          disabled={isDisabled}
          type="number"
          name="target"
          onChange={onChangeHandler}
          value={data.target}
          className="rounded form-control w-100 numberCountInput"
        />
      </td>
      <td>
        <textarea
          name="comment"
          value={data.comment}
          onChange={onChangeHandler}
          className="rounded form-control w-100 addFromTxtArea"
        />
      </td>
      <td>
        <div className="fileUploader">
          {data.attachment?.length > 0 && (
            <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              {renderAttachments()}
            </div>
          )}
          <div className="chooseFile" style={{ position: "relative", marginTop: "5px" }}>
            <div
              style={{
                position: "absolute",
                border: "1px solid #ccc",
                borderRadius: "4px",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Choose Files
            </div>
            <input
              multiple
              name="attachment"
              onChange={handleFileUpload}
              type="file"
              style={{
                opacity: 0,
                cursor: "pointer",
                zIndex: 1,
                top: 0,
                width: "100%",
                height: "100%",
              }}
              className="form-control w-100 h-100"
            />
          </div>
        </div>
      </td>
    </tr>
  );
};

function RowDropdown({ groupCDropdownData, setGroupCDropdown, group, type }) {
  const handleChange = (e) => {
    setGroupCDropdown(e.target.value || groupCDropdownData);
  };

  if (["B"].includes(group) && type === "water") {
    return (
      <div className="mb-2">
        <table>
          <tbody>
            <tr>
              <td colSpan={4}>
                <select
                  className="form-select"
                  name="groupCDropdown"
                  value={groupCDropdownData}
                  required
                  style={{ width: "250px" }}
                  onChange={handleChange}
                >
                  <option value="0">Without Treatment</option>
                  <option value="1">With Treatment</option>
                </select>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  return null;
}



export default observer(EnironmentApproval);
