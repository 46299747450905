import { action, computed, makeObservable, observable, toJS } from "mobx";
import fetchData from "../utils/fetchData";
import { getFormDataUrl } from "../utils/helpers";

export default class FormStore {
  // initial values
  fieldsRaw = [];
  formsRaw = {};
  dataRaw = [];
  formExistsRaw = false;
  approvalFieldsRaw = [];
  logRaw = [];
  // store constructor
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      //values
      fieldsRaw: observable,
      formsRaw: observable,
      dataRaw: observable,
      formExistsRaw: observable,
      approvalFieldsRaw: observable,
      //computed
      fields: computed,
      forms: computed,
      data: computed,
      approvalFields: computed,
      //actions
      fetchFields: action.bound,
      fetchForms: action.bound,
      reset: action.bound,
      create: action.bound,
      getFormData: action.bound,
      createBasicForm: action.bound,
      recordExists: action.bound,
      setFormExists: action.bound,
      fetchApprovalFields: action.bound,
      updateFields: action.bound,
      approveForm: action.bound,
      deleteForm: action.bound,
      logData: action.bound,
      logRaw: observable,
    });
  }

  // computed values
  get fields() {
    return toJS(this.fieldsRaw);
  }

  get log() {
    return toJS(this.logRaw);
  }

  get forms() {
    return toJS(this.formsRaw);
  }

  get data() {
    return toJS(this.dataRaw);
  }

  get formExists() {
    return toJS(this.formExistsRaw);
  }

  get approvalFields() {
    return toJS(this.approvalFieldsRaw);
  }

  // reset function
  reset() {
    this.fieldsRaw = [];
    this.formsRaw = {};
    this.formExistsRaw = false;
    this.approvalFieldsRaw = [];
    this.logRaw = [];
  }
}

// actions

FormStore.prototype.fetchFields = function () {
  const { catId, subCatId, kpiId } = this.rootStore.companyStore;
  const body = JSON.stringify({
    catId,
    subCatId,
    kpiId,
  });
  fetchData(`company/getAssignedFields`, {
    method: "POST",
    body,
  })
    .then((response) => response.json())
    .then((result) => {
      if (result.status) {
        this.fieldsRaw = result.data;
      }
    })
    .catch((error) => console.error("Eroooroorororoor", error));
};

FormStore.prototype.create = function ({
  payload,
  groupPayload,
  update,
  formId,
  showAlert,
  endpoint,
  setEditForm,
  navigate,
  totalGroups,
  frequency,
  groupData,
  pageUrl,
  redirectUrl,
  globalUnit,
  group,
  filled,
}) {
  const token = JSON.parse(
    localStorage.getItem("access-token") ||
      localStorage.getItem("admin-access-token")
  )?.token;
  const formData = new FormData();
  formData.append("data", JSON.stringify(payload));
  formData.append("groupPayload", JSON.stringify(groupPayload)); 
  payload.forEach((pay, index) => {
    if (pay?.attachment?.length) {
      for (let i = 0; i < pay.attachment.length; i++) {
        formData.append(`file[]`, pay.attachment[i]);
        formData.append(`index[]`, pay.fieldId || index);
      }
    }
  });
  fetch(
    process.env.REACT_APP_BASE_URL +
      `${endpoint}/${update ? "update" : "create"}${
        formId ? `?formId=${formId}` : ""
      }`,
    {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((res) => res.json())
    .then((response) => {
      if (response.status) {
        showAlert(response.message, true);
        if (update) {
          setEditForm("");
        }
        const groupsFilled =
          endpoint === "add-water"
            ? [...(filled || []), ...(update ? [] : [group])]
            : response.data.groupsFilled;
        if (groupsFilled.length === totalGroups) {
          navigate(redirectUrl);
          return;
        }
        navigate(pageUrl, {
          state: {
            freq: frequency,
            formid: response.data.formId,
            groupsFilled,
            formData: groupData,
            unitGlobal: globalUnit,
          },
        });
        return;
      }
      showAlert(response.message);
    })
    .catch((err) => {
      showAlert(err.message);
      console.error(err);
    });
};

FormStore.prototype.getFormData = function ({
  signal,
  type,
  formId,
  showAlert,
}) {
  fetchData(`${getFormDataUrl(type)}?formId=${formId}`, {
    method: "POST",
    signal,
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) {
        this.dataRaw = data.data;
        return;
      }
      showAlert?.(data.message);
    })
    .catch((err) => {
      showAlert?.(err.message);
      console.error(err);
    });
};

FormStore.prototype.createBasicForm = function ({
  data,
  fullData,
  companyUnit,
  year,
  showAlert,
  navigate,
  formId,
  update,
  setEditForm,
}) {
  const payload = data?.values?.map((ele) => ({
    fieldId: ele.fieldId,
    fieldName: ele.fieldName,
    group: ele.group,
    value: ele.value,
    companyUnit,
    year,
  }));
  const file = data.file;
  const token = JSON.parse(
    localStorage.getItem("access-token") ||
      localStorage.getItem("admin-access-token")
  )?.token;
  const formData = new FormData();
  formData.append("payload", JSON.stringify(payload));
  if (file?.length) {
    for (let i = 0; i < file.length; i++) {
      formData.append(`file[]`, file[i]);
    }
  }  formData.append("group", data.group);
  fetch(
    process.env.REACT_APP_BASE_URL +
      `basic/${update ? "update" : "create"}${
        formId ? `?formId=${formId}` : ""
      }`,
    {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((res) => res.json())
    .then((result) => {
      showAlert(result.message, result.status);
      if (result.status) {
        setEditForm?.("");
        if (result?.data?.groupsFilled?.length === 9) {
          navigate("/company/basic_parameter");
          return;
        }
        navigate("/company/basic_parameter", {
          state: {
            formData: fullData,
            formYear: year,
            groupsFilled: result.data.groupsFilled,
            formid: result.data.formId,
          },
        });
      }
    })
    .catch((err) => {
      showAlert(err.message);
      console.error(err);
    });
};

FormStore.prototype.recordExists = function ({ frequency, type }) {
  const urlParams = new URLSearchParams({ ...frequency, type });
  fetchData(`form/exists?${urlParams.toString()}`)
    .then((res) => res.json())
    .then((result) => {
      this.formExistsRaw = result.data;
    });
};

FormStore.prototype.setFormExists = function (payload = false) {
  this.formExistsRaw = payload;
};

FormStore.prototype.fetchForms = function ({ type, page, pageSize, filter }) {
  const body = JSON.stringify(filter);
  const params = new URLSearchParams({ type, page, pageSize });
  fetchData(`form/list?${params.toString()}`, {
    method: "POST",
    body,
  })
    .then((res) => res.json())
    .then((res) => {
      this.formsRaw = res.data;
    })
    .catch((err) => {
      console.error("[Error]", err);
    });
};

FormStore.prototype.fetchApprovalFields = function ({
  formId,
  type,
  year,
  month,
}) {
  const params = new URLSearchParams({
    formId,
    type,
    year,
    ...(month ? { months: month } : {}),
  });
  fetchData(`form/approvalFields?${params.toString()}`)
    .then((res) => res.json())
    .then((res) => {
      if (res.status) {
        this.approvalFieldsRaw = res.data;
      }
    });
};

FormStore.prototype.updateFields = function ({
  data,
  type,
  formId,
  showAlert,
  year,
  month,
}) {
  const { social, governance } = this.rootStore;
  const token = JSON.parse(
    localStorage.getItem("access-token") ||
      localStorage.getItem("admin-access-token")
  )?.token;
  const formData = new FormData();
  formData.append("data", JSON.stringify(data));
  data.forEach((pay) => {  
    if (pay?.attachment?.length) {
      for (let i = 0; i < pay.attachment.length; i++) {
        // Check if the attachment is an actual File object
        if (pay.attachment[i] instanceof File) {
          formData.append(`file[]`, pay.attachment[i]); // Append file
          formData.append(`index[]`, pay.fieldId || i); // Associate with fieldId
        } else {
          console.log(pay.attachment[i], "Skipping, as it's a file path");
        }
      }
    }
  });
  const params = new URLSearchParams({
    type,
    formId,
  });
  fetchData(`form/updateFields?${params.toString()}`, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      showAlert(res.message, res.status);
      if (res.status) {
        if (type === "social") {
          if (month) {
            social.fetch({ year, month });
          } else {
            social.fetch({ year });
          }
        } else if (type === "governance") {
          governance.fetch({ year });
        } else {
          this.fetchApprovalFields({ formId, type, year, month });
        }
      }
    });
};

FormStore.prototype.approveForm = function ({
  type,
  formId,
  showAlert,
  status,
  goback,
  navigate,
  year,
  month,
}) {
  const params = new URLSearchParams({
    type,
    formId,
    status,
    year,
    ...(month ? { months: month } : {}),
  });
  fetchData(`form/formStatus?${params.toString()}`, {
    method: "POST",
  })
    .then((res) => res.json())
    .then((res) => {
      showAlert(res.message, res.status);
      if (res.status) {
        if (type === "social" || type === "governance" || type === "basic") {
          if (goback) {
            navigate(-1);
          } else {
            window.location.reload();
          }
        } else {
          window.location.reload();
        }
      }
    });
};

FormStore.prototype.deleteForm = function ({
  type,
  formId,
  showAlert,
  status,
  goback,
  navigate,
  year,
  month,
}) {
  const params = new URLSearchParams({
    type,
    formId,
    status,
    year,
    ...(month ? { months: month } : {}),
  });
  fetchData(`form/formDelete?${params.toString()}`, {
    method: "POST",
  })
    .then((res) => res.json())
    .then((res) => {
      showAlert(res.message, res.status);
      if (res.status) {
        if (type === "social" || type === "governance" || type === "basic") {
          if (goback) {
            navigate(-1);
          } else {
            window.location.reload();
          }
        } else {
          window.location.reload();
        }
      }
    });
};

FormStore.prototype.logData = function ({ formId }) {
  const params = new URLSearchParams({ formId });
  fetchData(`form/logData?${params.toString()}`, {
    method: "POST",
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status) {
        this.logRaw = res.data;
      }
    });
};
