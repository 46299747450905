import { observer } from "mobx-react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import useStore from "../../../../hooks/useStore";
import { useEffect, useMemo, useState } from "react";
import { findKpi, socialNestedData } from "../../../../utils/helpers";
import {
  GROUPS_WITH_TOTAL,
  NUMERIC_FIELDS,
  SMALL_GROUPS,
  SOCIAL_GROUP_CONSTANTS,
  USER_ROLE,
} from "../../../../constants/constants";
import { Accordion } from "react-bootstrap";
import InfoIcon from "../../../../components/infoIcon";
import numToRoman from "../../../../helpers/numToRoman";
import { useAuth } from "../../../../context/authProvider";

const ShowForm = ({ groupConstant }) => {
  
  const {
    social: { formData },

  } = useStore();
  const [expanded, setExpanded] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(formData);
  }, [formData]);



  return (
    <div className="add-water-page">
      <div className="p-4">
        <Accordion
          className="water-table-accordion"
          defaultActiveKey={0}
          activeKey={expanded}
          onSelect={(eventKey) => {
            setExpanded(eventKey);
          }}
        >
          {socialNestedData(data, SOCIAL_GROUP_CONSTANTS[groupConstant])?.map(
            (accordion, index) => (
              <Accordion.Item
                key={`${accordion.group}-accordion-item`}
                eventKey={index}
              >
                <Accordion.Header>
                  <tr style={{ display: "flex", gap: "4px" }}>
                    <td>{index + 1}</td>
                    <td colSpan={4}>
                      {SOCIAL_GROUP_CONSTANTS[groupConstant][accordion.group]}
                    </td>
                  </tr>
                </Accordion.Header>
                <Accordion.Body>
                  
                    <table className="table align-middle table-bordered table-nowrap mb-0">
                      <thead>
                        <tr>
                          {[
                            "Sr. No.",
                            "Title",
                            "Value",
                            "Comment",
                            "Attachment",
                          ].map((head) => (
                            <th key={head}>{head}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {accordion?.value?.map((head, ind) =>
                          head?.label ? (
                            <>
                              <HeadRow
                                label={head.label}
                                sr={numToRoman(ind + 1)}
                                showAttachment
                                setData={setData}
                                data={data}
                                group={head.group}
                                attachment={
                                  data?.find((ele) =>
                                    ele?.group?.startsWith(head.group)
                                  )?.attachment
                                }
                                url={
                                  data?.find((ele) =>
                                    ele?.group?.startsWith(head.group)
                                  )?.url
                                }
                              />
                              {head?.value?.map((ele, i) =>
                                ele?.label ? (
                                  <>
                                    <HeadRow
                                      label={ele.label}
                                      sr={SMALL_GROUPS[i]}
                                    />
                                    {ele?.value?.map((e) => (
                                      <Row data={e} sr="*" setData={setData} />
                                    ))}
                                    <RowTotal
                                      value={ele?.value?.reduce(
                                        (acc, curr) => acc + (Number(curr.value) || 0),
                                        0
                                      )}
                                    />
                                  </>
                                ) : (
                                  <Row
                                    data={ele}
                                    sr={SMALL_GROUPS[i]}
                                    setData={setData}
                                  />
                                )
                              )}
                               {GROUPS_WITH_TOTAL[groupConstant]?.includes(head?.value) && (
                                <RowTotal
                                  value={head?.value?.reduce(
                                    (acc, curr) => acc + (Number(curr.value) || 0),
                                    0
                                  )}
                                />
                              )}
                            </>
                          ) : (
                            <Row
                              showAttachment
                              sr={ind + 1}
                              data={head}
                              setData={setData}
                            />
                           
                            
                          )
                        )}
                       
                      </tbody>
                    </table>
                </Accordion.Body>
              </Accordion.Item>
            )
          )}
        </Accordion>
       
      </div>
    </div>
  );
};

function Row({ data, sr, showAttachment, setData }) {
  
  return (
    <tr key={`${data.fieldId}-row`}>
      <td>{sr}</td>
      <td>
        <span
          style={{
            display: "flex",
            gap: "5px",
          }}
        >
          {data?.fieldName}
          {data?.guidance && (
            <InfoIcon content={data?.guidance} position="top" />
          )}
        </span>
      </td>
      <td>
      {data.value}
      </td>
      <td>
      {data.comment}
      </td>
      {showAttachment && (
      
        <td>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          {/* Display uploaded files */}
          {data?.attachment.length > 0 ?
            data?.attachment.map((file, index) => {
              // Find the corresponding signed URL from 'url' array
              const fileUrlObj = data.url.find((item) => item.attachment === file);
              const fileUrl = fileUrlObj ? fileUrlObj.url : "#";
  
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <a href={fileUrl} rel="noopener noreferrer">
                    Download {index + 1}
                    {/* {typeof file === "string" ? decodeURIComponent(file.split("/").pop().split("?")[0]) : file.name} */}
                  </a>
              
                </div>
              );
            })
            :
            <div>N/A</div>
          }
        </div>
      </td>
      )}
    </tr>
  );
}

function HeadRow({ label, showAttachment, sr, attachment, setData, group, url,data }) {
  


  return (
    <tr key={label}>
      <td key="sr-no">{sr}</td>
      <td key={label} colSpan={showAttachment ? 3 : 4}>
        {["For Workers", "For Employees"].includes(label) ? (
          <b>{label}</b>
        ) : (
          label
        )}
      </td>
      {showAttachment && (
        <td>
           <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          {/* Display uploaded files */}
          {attachment.length > 0 ?
            attachment.map((file, index) => {
              // Find the corresponding signed URL from 'url' array
              const fileUrlObj = url.find((item) => item.attachment === file);
              const fileUrl = fileUrlObj ? fileUrlObj.url : "#";
  
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <a href={fileUrl} rel="noopener noreferrer">
                    {/* {typeof file === "string" ? decodeURIComponent(file.split("/").pop().split("?")[0]) : file.name} */}

                    Download {index + 1}
                  </a>
                  
                </div>
              );
            })
            :
            <div>N/A</div>
          }
  
          
        </div>
        </td>
      )}
    </tr>
  );
}


function RowTotal({ value, className }) {
  return (
    <tr key={`${value}-row-total`} className={`subTotalTr ${className}`}>
      <td colSpan={2}>Total</td>
      <td>{value || 0}</td>
      <td colSpan={2}></td>
    </tr>
  );
}

export default observer(ShowForm);
